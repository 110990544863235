import React from "react";

export default function ArrowDowm() {
  return (
    <svg
      width="14"
      height="7"
      viewBox="0 0 14 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00002 6.60047C6.47502 6.60047 5.95002 6.39797 5.55252 6.00047L0.662515 1.11047C0.445015 0.892968 0.445015 0.532969 0.662515 0.315469C0.880015 0.0979688 1.24001 0.0979688 1.45751 0.315469L6.34752 5.20547C6.70752 5.56547 7.29252 5.56547 7.65252 5.20547L12.5425 0.315469C12.76 0.0979688 13.12 0.0979688 13.3375 0.315469C13.555 0.532969 13.555 0.892968 13.3375 1.11047L8.44752 6.00047C8.05002 6.39797 7.52502 6.60047 7.00002 6.60047Z"
        fill="#222222"
      />
    </svg>
  );
}
