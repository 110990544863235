import React from "react";

export default function Refernce() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_412_8228)">
        <path
          d="M6.22954 9.04996V11.1344C6.22954 11.3897 6.43649 11.5966 6.6917 11.5966H8.41227V13.3172C8.41227 13.5724 8.61922 13.7793 8.87443 13.7793H10.9588C11.214 13.7793 11.421 13.5724 11.421 13.3172V11.5966H13.1416C13.3968 11.5966 13.6038 11.3897 13.6038 11.1344V9.04996C13.6038 8.79471 13.3968 8.5878 13.1416 8.5878H11.421V6.86723C11.421 6.61198 11.214 6.40507 10.9588 6.40507H8.87443C8.61922 6.40507 8.41227 6.61198 8.41227 6.86723V8.5878H6.6917C6.43645 8.5878 6.22954 8.79471 6.22954 9.04996ZM7.15386 9.51212H8.87443C9.12963 9.51212 9.33659 9.30521 9.33659 9.04996V7.32939H10.4967V9.04996C10.4967 9.30521 10.7036 9.51212 10.9588 9.51212H12.6794V10.6723H10.9588C10.7036 10.6723 10.4967 10.8792 10.4967 11.1344V12.855H9.33659V11.1344C9.33659 10.8792 9.12963 10.6723 8.87443 10.6723H7.15386V9.51212ZM15.8742 0H2.12598C1.87077 0 1.66382 0.206909 1.66382 0.462159V17.5378C1.66382 17.7931 1.87077 18 2.12598 18H15.8742C16.1295 18 16.3364 17.7931 16.3364 17.5378V0.462159C16.3364 0.206909 16.1295 0 15.8742 0ZM6.23259 0.924319H7.44174V2.95186L7.10357 2.71325C7.02371 2.65691 6.93045 2.62872 6.83714 2.62872C6.74383 2.62872 6.65056 2.65691 6.57066 2.71325L6.23259 2.95181V0.924319ZM3.49697 17.0757H2.58814V0.924319H3.49697V17.0757ZM15.412 17.0757H4.42129V0.924319H5.30827V3.84355C5.30827 4.01617 5.40449 4.17446 5.55774 4.25385C5.71104 4.3333 5.89572 4.32068 6.03686 4.22113L6.83709 3.65647L7.63741 4.22113C7.77846 4.32068 7.96328 4.3333 8.11649 4.25385C8.26974 4.17441 8.36596 4.01617 8.36596 3.84355V0.924319H15.412V17.0757H15.412Z"
          fill="black"
          fill-opacity="0.54"
        />
      </g>
      <defs>
        <clipPath id="clip0_412_8228">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
