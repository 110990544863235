import React from "react";

export default function StudiesIcon() {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_411_5370)">
        <path
          d="M16.423 1.52052H13.955C13.8208 1.07909 13.445 0.760259 13.0032 0.760259H11.9595C11.6427 0.28676 11.1409 0 10.6093 0C10.0777 0 9.57593 0.28676 9.25913 0.760259H8.21545C7.77361 0.760259 7.39777 1.07909 7.26363 1.52052H4.79564C4.05316 1.52052 3.4491 2.19197 3.4491 3.01728V20.5032C3.4491 21.3285 4.05316 22 4.79564 22H16.423C17.1655 22 17.7695 21.3285 17.7695 20.5032V3.01728C17.7695 2.19197 17.1655 1.52052 16.423 1.52052ZM8.21545 4.13391H13.0032C13.3034 4.13391 13.5731 3.98656 13.7573 3.75378H15.7604V19.7667H5.45823V3.75378H7.46134C7.64554 3.98656 7.91523 4.13391 8.21545 4.13391ZM8.21545 1.473H9.4311C9.54524 1.473 9.65078 1.40558 9.70819 1.29591C9.8994 0.930747 10.2363 0.712743 10.6093 0.712743C10.9824 0.712743 11.3192 0.930747 11.5104 1.29586C11.5678 1.40553 11.6734 1.473 11.7875 1.473H13.0032C13.2035 1.473 13.3665 1.65418 13.3665 1.87689V3.01728C13.3665 3.23999 13.2035 3.42117 13.0032 3.42117H8.21545C8.01509 3.42117 7.85209 3.23999 7.85209 3.01728V1.87689C7.85209 1.65418 8.01509 1.473 8.21545 1.473ZM17.1283 20.5032C17.1283 20.9355 16.8119 21.2873 16.423 21.2873H4.79564C4.40673 21.2873 4.09031 20.9355 4.09031 20.5032V3.01728C4.09031 2.58498 4.40673 2.23326 4.79564 2.23326H7.21088V3.01728C7.21088 3.02526 7.21127 3.0331 7.21144 3.04104H5.13763C4.96052 3.04104 4.81702 3.2006 4.81702 3.39741V20.1231C4.81702 20.3199 4.96052 20.4795 5.13763 20.4795H16.081C16.2581 20.4795 16.4016 20.3199 16.4016 20.1231V3.39741C16.4016 3.2006 16.2581 3.04104 16.081 3.04104H14.0072C14.0074 3.0331 14.0077 3.02526 14.0077 3.01728V2.23326H16.423C16.8119 2.23326 17.1283 2.58498 17.1283 3.01728V20.5032Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M10.6093 2.23423C10.6939 2.23423 10.7764 2.19621 10.8359 2.12969C10.8957 2.06364 10.9299 1.97146 10.9299 1.87786C10.9299 1.78425 10.8957 1.69207 10.8359 1.62602C10.7764 1.5595 10.6939 1.52148 10.6093 1.52148C10.5247 1.52148 10.4422 1.5595 10.3827 1.62602C10.3229 1.69207 10.2887 1.78377 10.2887 1.87786C10.2887 1.97194 10.3229 2.06364 10.3827 2.12969C10.4422 2.19621 10.5247 2.23423 10.6093 2.23423Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M8.55742 7.55454H14.3711C14.5482 7.55454 14.6917 7.39498 14.6917 7.19817C14.6917 7.00136 14.5482 6.8418 14.3711 6.8418H8.55742C8.38032 6.8418 8.23682 7.00136 8.23682 7.19817C8.23682 7.39498 8.38032 7.55454 8.55742 7.55454Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M8.55742 11.3573H14.3711C14.5482 11.3573 14.6917 11.1977 14.6917 11.0009C14.6917 10.8041 14.5482 10.6445 14.3711 10.6445H8.55742C8.38032 10.6445 8.23682 10.8041 8.23682 11.0009C8.23682 11.1977 8.38032 11.3573 8.55742 11.3573Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M8.55742 15.1581H9.58336C9.76047 15.1581 9.90397 14.9985 9.90397 14.8017C9.90397 14.6049 9.76047 14.4453 9.58336 14.4453H8.55742C8.38032 14.4453 8.23682 14.6049 8.23682 14.8017C8.23682 14.9985 8.38032 15.1581 8.55742 15.1581Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M8.55742 9.45493H14.3711C14.5482 9.45493 14.6917 9.29537 14.6917 9.09856C14.6917 8.90175 14.5482 8.74219 14.3711 8.74219H8.55742C8.38032 8.74219 8.23682 8.90175 8.23682 9.09856C8.23682 9.29537 8.38032 9.45493 8.55742 9.45493Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M8.55742 13.2577H9.92534C10.1024 13.2577 10.246 13.0981 10.246 12.9013C10.246 12.7045 10.1024 12.5449 9.92534 12.5449H8.55742C8.38032 12.5449 8.23682 12.7045 8.23682 12.9013C8.23682 13.0981 8.38032 13.2577 8.55742 13.2577Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M8.55742 17.0584H9.92534C10.1024 17.0584 10.246 16.8989 10.246 16.7021C10.246 16.5053 10.1024 16.3457 9.92534 16.3457H8.55742C8.38032 16.3457 8.23682 16.5053 8.23682 16.7021C8.23682 16.8989 8.38032 17.0584 8.55742 17.0584Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M6.84758 7.55454H7.18956C7.36667 7.55454 7.51017 7.39498 7.51017 7.19817C7.51017 7.00136 7.36667 6.8418 7.18956 6.8418H6.84758C6.67048 6.8418 6.52698 7.00136 6.52698 7.19817C6.52698 7.39498 6.67048 7.55454 6.84758 7.55454Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M6.84758 11.3573H7.18956C7.36667 11.3573 7.51017 11.1977 7.51017 11.0009C7.51017 10.8041 7.36667 10.6445 7.18956 10.6445H6.84758C6.67048 10.6445 6.52698 10.8041 6.52698 11.0009C6.52698 11.1977 6.67048 11.3573 6.84758 11.3573Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M6.84758 15.1581H7.18956C7.36667 15.1581 7.51017 14.9985 7.51017 14.8017C7.51017 14.6049 7.36667 14.4453 7.18956 14.4453H6.84758C6.67048 14.4453 6.52698 14.6049 6.52698 14.8017C6.52698 14.9985 6.67048 15.1581 6.84758 15.1581Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M6.84758 9.45493H7.18956C7.36667 9.45493 7.51017 9.29537 7.51017 9.09856C7.51017 8.90175 7.36667 8.74219 7.18956 8.74219H6.84758C6.67048 8.74219 6.52698 8.90175 6.52698 9.09856C6.52698 9.29537 6.67048 9.45493 6.84758 9.45493Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M6.84758 13.2577H7.18956C7.36667 13.2577 7.51017 13.0981 7.51017 12.9013C7.51017 12.7045 7.36667 12.5449 7.18956 12.5449H6.84758C6.67048 12.5449 6.52698 12.7045 6.52698 12.9013C6.52698 13.0981 6.67048 13.2577 6.84758 13.2577Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M6.84758 17.0584H7.18956C7.36667 17.0584 7.51017 16.8989 7.51017 16.7021C7.51017 16.5053 7.36667 16.3457 7.18956 16.3457H6.84758C6.67048 16.3457 6.52698 16.5053 6.52698 16.7021C6.52698 16.8989 6.67048 17.0584 6.84758 17.0584Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M12.6612 12.5449C11.5416 12.5449 10.6307 13.5574 10.6307 14.8019C10.6307 16.0464 11.5416 17.059 12.6612 17.059C13.1136 17.059 13.5319 16.8935 13.8698 16.6144C13.873 16.6119 13.8764 16.6102 13.8796 16.6076C13.8881 16.6004 13.8962 16.5929 13.9039 16.585C14.3827 16.1718 14.6918 15.5264 14.6918 14.8019C14.6918 13.5574 13.7808 12.5449 12.6612 12.5449ZM12.3406 13.2996V14.4456H11.3097C11.4305 13.8798 11.8317 13.4339 12.3406 13.2996ZM11.3097 15.1583H12.5009L13.2156 16.2175C13.0457 16.3001 12.8583 16.3462 12.6612 16.3462C12.0055 16.3462 11.4549 15.8385 11.3097 15.1583ZM13.7284 15.7895L12.9819 14.6832V13.2996C13.5938 13.461 14.0505 14.0731 14.0505 14.8019C14.0505 15.1772 13.9294 15.5216 13.7284 15.7895Z"
          fill="black"
          fill-opacity="0.54"
        />
      </g>
      <defs>
        <clipPath id="clip0_411_5370">
          <rect
            width="19.7921"
            height="22"
            fill="white"
            transform="translate(0.713257)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
