import React from "react";

export default function Files() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.167 21.8327H8.83366C4.74815 21.8327 2.7054 21.8327 1.43619 20.5634C0.166992 19.2943 0.166992 17.2515 0.166992 13.166V8.83268C0.166992 4.74717 0.166992 2.70443 1.43619 1.43522C2.7054 0.166016 4.75891 0.166016 8.86594 0.166016C9.52244 0.166016 10.0485 0.166016 10.4912 0.184064C10.4766 0.270655 10.469 0.35899 10.4686 0.4483L10.4587 3.5189C10.4586 4.70743 10.4584 5.75779 10.5723 6.60444C10.6957 7.52215 10.979 8.43971 11.7279 9.18856C12.4767 9.93747 13.3943 10.2208 14.312 10.3441C15.1587 10.458 16.209 10.4579 17.3975 10.4578H17.5003H21.7875C21.8337 11.0366 21.8337 11.747 21.8337 12.6925V13.166C21.8337 17.2515 21.8337 19.2943 20.5644 20.5634C19.2953 21.8327 17.2525 21.8327 13.167 21.8327Z"
          fill="black"
        />
      </g>
      <path
        d="M10.468 0.449255L10.458 3.51985C10.4579 4.70838 10.4578 5.75875 10.5717 6.60539C10.695 7.52311 10.9783 8.44068 11.7272 9.18959C12.4762 9.93839 13.3936 10.2217 14.3114 10.3451C15.1581 10.4589 16.2083 10.4588 17.3969 10.4587H21.7869C21.8003 10.6273 21.8098 10.8069 21.8165 11.0003H21.833C21.833 10.7105 21.833 10.5656 21.8223 10.395C21.761 9.42597 21.3582 8.3155 20.7838 7.53255C20.6827 7.39472 20.6135 7.31199 20.4753 7.14651C19.6167 6.11887 18.4866 4.83874 17.4997 3.95864C16.6213 3.1753 15.4183 2.31829 14.369 1.61752C13.4676 1.01546 13.0168 0.714422 12.3988 0.490681C12.235 0.431369 12.0189 0.365849 11.8497 0.324195C11.4337 0.221787 11.0287 0.186167 10.458 0.173828L10.468 0.449255Z"
        fill="black"
      />
    </svg>
  );
}
