import React from "react";

export default function NoResult() {
  return (
    <svg
      width="316"
      height="235"
      viewBox="0 0 316 235"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M209.947 196.177H207.068C206.735 196.177 206.466 195.907 206.466 195.575V175.491C206.466 175.158 206.736 174.889 207.068 174.889H209.947C210.279 174.889 210.549 175.159 210.549 175.491V195.575C210.549 195.907 210.28 196.177 209.947 196.177ZM207.671 194.972H209.344V176.093H207.671V194.972Z"
        fill="#D1D1D1"
      />
      <path
        d="M209.947 196.177H207.068C206.735 196.177 206.466 195.907 206.466 195.575V175.491C206.466 175.158 206.736 174.889 207.068 174.889H209.947C210.279 174.889 210.549 175.159 210.549 175.491V195.575C210.549 195.907 210.28 196.177 209.947 196.177ZM207.671 194.972H209.344V176.093H207.671V194.972Z"
        fill="#D1D1D1"
      />
      <path
        d="M206.942 196.177H204.232C203.899 196.177 203.63 195.907 203.63 195.574V176.667C203.63 176.334 203.9 176.064 204.232 176.064H206.942C207.275 176.064 207.545 176.334 207.545 176.667V195.574C207.544 195.907 207.275 196.177 206.942 196.177ZM204.834 194.972H206.339V177.269H204.834V194.972Z"
        fill="#D1D1D1"
      />
      <path
        d="M207.018 196.177H204.308C203.975 196.177 203.705 195.907 203.705 195.574V176.667C203.705 176.334 203.975 176.064 204.308 176.064H207.018C207.35 176.064 207.62 176.334 207.62 176.667V195.574C207.62 195.907 207.35 196.177 207.018 196.177ZM204.91 194.972H206.415V177.269H204.91V194.972Z"
        fill="#D1D1D1"
      />
      <path
        d="M213.221 196.171C212.937 196.171 212.685 195.97 212.63 195.681L209.322 178.295C209.26 177.968 209.475 177.652 209.802 177.59L212.294 177.116C212.45 177.087 212.613 177.119 212.745 177.21C212.877 177.3 212.968 177.438 212.998 177.595L216.306 194.981C216.336 195.138 216.302 195.301 216.212 195.432C216.122 195.565 215.984 195.656 215.827 195.686L213.335 196.16C213.296 196.168 213.259 196.171 213.221 196.171ZM210.619 178.662L213.701 194.864L215.009 194.615L211.927 178.413L210.619 178.662Z"
        fill="#D1D1D1"
      />
      <path
        d="M213.221 196.171C212.937 196.171 212.685 195.97 212.63 195.681L209.322 178.295C209.26 177.968 209.475 177.652 209.802 177.59L212.294 177.116C212.45 177.087 212.613 177.119 212.745 177.21C212.877 177.3 212.968 177.438 212.998 177.595L216.306 194.981C216.336 195.138 216.302 195.301 216.212 195.432C216.122 195.565 215.984 195.656 215.827 195.686L213.335 196.16C213.296 196.168 213.259 196.171 213.221 196.171ZM210.619 178.662L213.701 194.864L215.009 194.615L211.927 178.413L210.619 178.662Z"
        fill="#D1D1D1"
      />
      <path
        d="M204.062 196.508H170.016C169.577 196.508 169.222 196.153 169.222 195.714V184.288C169.222 183.85 169.577 183.494 170.016 183.494H204.062C204.501 183.494 204.856 183.849 204.856 184.288V195.714C204.856 196.152 204.501 196.508 204.062 196.508ZM170.809 194.92H203.269V185.082H170.809V194.92Z"
        fill="#D1D1D1"
      />
      <path
        d="M204.127 196.508H170.081C169.643 196.508 169.287 196.153 169.287 195.714V184.288C169.287 183.85 169.642 183.494 170.081 183.494H204.127C204.566 183.494 204.921 183.849 204.921 184.288V195.714C204.921 196.152 204.566 196.508 204.127 196.508ZM170.875 194.92H203.334V185.082H170.875V194.92Z"
        fill="#D1D1D1"
      />
      <path
        d="M203.958 188.633H170.081C169.643 188.633 169.287 188.277 169.287 187.839C169.287 187.4 169.642 187.045 170.081 187.045H203.958C204.396 187.045 204.751 187.4 204.751 187.839C204.751 188.277 204.396 188.633 203.958 188.633Z"
        fill="#D1D1D1"
      />
      <path
        d="M190.077 191H183.669C182.593 191 181.718 190.124 181.718 189.048C181.718 187.971 182.593 187.096 183.669 187.096H190.077C191.153 187.096 192.029 187.971 192.029 189.048C192.029 190.124 191.153 191 190.077 191ZM183.669 188.683C183.472 188.683 183.305 188.85 183.305 189.048C183.305 189.245 183.472 189.412 183.669 189.412H190.077C190.275 189.412 190.442 189.245 190.442 189.048C190.442 188.85 190.275 188.683 190.077 188.683H183.669Z"
        fill="#D1D1D1"
      />
      <path
        d="M190.077 190.949H183.669C182.593 190.949 181.718 190.073 181.718 188.997C181.718 187.92 182.593 187.045 183.669 187.045H190.077C191.153 187.045 192.029 187.92 192.029 188.997C192.029 190.073 191.153 190.949 190.077 190.949ZM183.669 188.632C183.472 188.632 183.305 188.799 183.305 188.997C183.305 189.194 183.472 189.361 183.669 189.361H190.077C190.275 189.361 190.442 189.194 190.442 188.997C190.442 188.799 190.275 188.632 190.077 188.632H183.669Z"
        fill="#D1D1D1"
      />
      <path
        d="M200.457 185.059H171.822C171.384 185.059 171.028 184.703 171.028 184.265V172.839C171.028 172.401 171.384 172.045 171.822 172.045H200.457C200.895 172.045 201.25 172.4 201.25 172.839V184.265C201.25 184.703 200.895 185.059 200.457 185.059ZM172.616 183.471H199.663V173.633H172.616V183.471Z"
        fill="#D1D1D1"
      />
      <path
        d="M200.513 185.059H171.878C171.44 185.059 171.084 184.703 171.084 184.265V172.839C171.084 172.401 171.439 172.045 171.878 172.045H200.513C200.951 172.045 201.306 172.4 201.306 172.839V184.265C201.306 184.703 200.951 185.059 200.513 185.059ZM172.672 183.471H199.719V173.633H172.672V183.471Z"
        fill="#D1D1D1"
      />
      <path
        d="M200.37 177.183H171.878C171.44 177.183 171.084 176.828 171.084 176.39C171.084 175.951 171.439 175.596 171.878 175.596H200.37C200.808 175.596 201.164 175.951 201.164 176.39C201.164 176.828 200.808 177.183 200.37 177.183Z"
        fill="#D1D1D1"
      />
      <path
        d="M188.607 179.55H183.394C182.371 179.55 181.538 178.718 181.538 177.694V177.502C181.538 176.479 182.371 175.646 183.394 175.646H188.607C189.63 175.646 190.463 176.479 190.463 177.502V177.694C190.463 178.718 189.63 179.55 188.607 179.55ZM183.394 177.234C183.248 177.234 183.125 177.357 183.125 177.502V177.694C183.125 177.84 183.248 177.963 183.394 177.963H188.607C188.752 177.963 188.875 177.84 188.875 177.694V177.502C188.875 177.357 188.752 177.234 188.607 177.234H183.394Z"
        fill="#D1D1D1"
      />
      <path
        d="M188.607 179.499H183.394C182.371 179.499 181.538 178.667 181.538 177.644V177.451C181.538 176.428 182.371 175.596 183.394 175.596H188.607C189.63 175.596 190.463 176.428 190.463 177.451V177.644C190.463 178.667 189.63 179.499 188.607 179.499ZM183.394 177.183C183.248 177.183 183.125 177.306 183.125 177.451V177.644C183.125 177.789 183.248 177.912 183.394 177.912H188.607C188.752 177.912 188.875 177.789 188.875 177.644V177.451C188.875 177.306 188.752 177.183 188.607 177.183H183.394Z"
        fill="#D1D1D1"
      />
      <path
        d="M309.866 202.407H235.32C235.322 202.352 235.33 202.298 235.33 202.243C235.33 198.902 231.84 196.193 227.535 196.193H91.3871C87.0822 196.193 83.5924 198.902 83.5924 202.243C83.5924 202.298 83.6013 202.352 83.603 202.407H6.45819C3.07096 202.407 0.325195 204.539 0.325195 207.167C0.325195 209.796 3.07096 211.928 6.45819 211.928H47.3791C47.1069 212.628 46.9571 213.371 46.9571 214.14C46.9571 218.373 51.3776 221.803 56.8306 221.803H263.911C269.364 221.803 273.785 218.373 273.785 214.14C273.785 213.371 273.635 212.628 273.363 211.928H309.867C313.254 211.928 316 209.796 316 207.167C316 204.539 313.254 202.407 309.866 202.407Z"
        fill="#909090"
      />
      <g opacity="0.48">
        <path
          d="M111.752 228.591H46.0649C45.456 228.591 44.958 228.093 44.958 227.484C44.958 226.875 45.4564 226.377 46.0649 226.377H111.752C112.361 226.377 112.859 226.875 112.859 227.484C112.859 228.093 112.361 228.591 111.752 228.591Z"
          fill="#424243"
        />
      </g>
      <g opacity="0.48">
        <path
          d="M117.953 228.591H116.329C115.72 228.591 115.222 228.093 115.222 227.484C115.222 226.875 115.72 226.377 116.329 226.377H117.953C118.562 226.377 119.06 226.875 119.06 227.484C119.06 228.093 118.562 228.591 117.953 228.591Z"
          fill="#424243"
        />
      </g>
      <g opacity="0.48">
        <path
          d="M173.087 234.506H109.171C108.522 234.506 107.99 233.975 107.99 233.326C107.99 232.676 108.522 232.145 109.171 232.145H173.087C173.736 232.145 174.268 232.676 174.268 233.326C174.268 233.975 173.736 234.506 173.087 234.506Z"
          fill="#424243"
        />
      </g>
      <g opacity="0.48">
        <path
          d="M180.322 234.506H177.369C176.72 234.506 176.188 233.975 176.188 233.326C176.188 232.676 176.72 232.145 177.369 232.145H180.322C180.971 232.145 181.503 232.676 181.503 233.326C181.503 233.975 180.972 234.506 180.322 234.506Z"
          fill="#424243"
        />
      </g>
      <g opacity="0.48">
        <path
          d="M268.965 229.35H205.049C204.4 229.35 203.868 228.819 203.868 228.169C203.868 227.52 204.4 226.988 205.049 226.988H268.965C269.614 226.988 270.146 227.52 270.146 228.169C270.146 228.819 269.614 229.35 268.965 229.35Z"
          fill="#424243"
        />
      </g>
      <g opacity="0.48">
        <path
          d="M276.198 229.35H273.245C272.596 229.35 272.064 228.819 272.064 228.169C272.064 227.52 272.596 226.988 273.245 226.988H276.198C276.847 226.988 277.379 227.52 277.379 228.169C277.379 228.819 276.847 229.35 276.198 229.35Z"
          fill="#424243"
        />
      </g>
      <path
        d="M62.7069 46.5415H27.6488C26.9781 46.5415 26.4346 45.9977 26.4346 45.3273V1.61848C26.4346 0.948138 26.9784 0.404297 27.6488 0.404297H62.7069C63.3773 0.404297 63.9211 0.948138 63.9211 1.61848V45.3273C63.9211 45.998 63.3773 46.5415 62.7069 46.5415ZM28.8629 44.1131H61.4924V2.83299H28.8629V44.1131Z"
        fill="#D1D1D1"
      />
      <path
        d="M57.8936 42.0136H32.262C31.5914 42.0136 31.0479 41.4697 31.0479 40.7994V6.22394C31.0479 5.55327 31.5917 5.00977 32.262 5.00977H57.8936C58.5643 5.00977 59.1078 5.5536 59.1078 6.22394V40.7994C59.1078 41.4697 58.564 42.0136 57.8936 42.0136ZM33.4762 39.5849H56.6795V7.43813H33.4762V39.5849Z"
        fill="#D1D1D1"
      />
      <path
        d="M109.049 46.5415H73.9905C73.3199 46.5415 72.7764 45.9977 72.7764 45.3273V1.61848C72.7764 0.948138 73.3202 0.404297 73.9905 0.404297H109.049C109.719 0.404297 110.263 0.948138 110.263 1.61848V45.3273C110.263 45.998 109.719 46.5415 109.049 46.5415ZM75.2047 44.1131H107.834V2.83299H75.2047V44.1131Z"
        fill="#D1D1D1"
      />
      <path
        d="M104.235 42.0136H78.6038C77.9332 42.0136 77.3896 41.4697 77.3896 40.7994V6.22394C77.3896 5.55327 77.9335 5.00977 78.6038 5.00977H104.235C104.906 5.00977 105.449 5.5536 105.449 6.22394V40.7994C105.449 41.4697 104.906 42.0136 104.235 42.0136ZM79.818 39.5849H103.021V7.43813H79.818V39.5849Z"
        fill="#D1D1D1"
      />
      <path
        d="M154.781 158.904C178.056 158.904 182.966 149.477 182.574 148.263C181.809 145.894 178.891 121.941 174.345 112.42C169.8 102.899 166.195 102.713 162.904 102.713C159.613 102.713 155.988 102.713 154.734 102.713C153.481 102.713 149.876 102.713 146.584 102.713C143.293 102.713 139.688 102.899 135.143 112.42C130.598 121.941 127.68 145.894 126.914 148.263C126.523 149.477 131.506 158.904 154.781 158.904Z"
        fill="#F9F8F3"
      />
      <path
        d="M184.232 148.019C184.072 147.522 183.677 145.143 183.259 142.624C181.809 133.868 179.38 119.213 175.918 111.961C171.2 102.08 167.07 101.262 162.904 101.262H146.584C142.418 101.262 138.288 102.08 133.57 111.961C130.108 119.213 127.68 133.866 126.229 142.621C125.811 145.142 125.417 147.522 125.256 148.019C124.861 149.242 125.658 150.707 126.396 151.72C128.414 154.492 135.219 160.939 154.781 160.939C174.287 160.939 181.071 154.513 183.082 151.75C183.826 150.728 184.63 149.251 184.232 148.019ZM175.772 153.348C172.344 155.221 165.92 157.453 154.781 157.453H154.78C143.641 157.453 137.2 155.221 133.757 153.348C130.208 151.418 128.92 149.389 128.67 148.74C128.886 147.878 129.169 146.196 129.667 143.191C130.994 135.18 133.465 120.269 136.716 113.462C140.876 104.746 143.778 104.746 146.585 104.746H162.904C165.71 104.746 168.612 104.746 172.773 113.462C176.023 120.27 178.494 135.182 179.822 143.193C180.321 146.206 180.604 147.889 180.82 148.747C180.579 149.407 179.343 151.397 175.772 153.348Z"
        fill="#444445"
      />
      <path
        d="M179.055 151.606C178.911 151.606 178.766 151.567 178.634 151.487C177.75 150.949 177.467 149.24 176.859 145.082C176.572 143.124 176.247 140.904 175.784 138.559C175.597 137.613 175.397 136.502 175.165 135.216C173.92 128.317 171.837 116.769 167.902 110.902C167.869 110.855 167.818 110.782 167.763 110.701C167.055 109.673 165.738 107.76 162.641 107.76H146.846C143.749 107.76 142.432 109.674 141.725 110.701C141.669 110.782 141.618 110.856 141.572 110.92C137.651 116.768 135.568 128.315 134.323 135.213C134.091 136.501 133.89 137.612 133.703 138.56C133.24 140.905 132.915 143.124 132.628 145.082C132.02 149.24 131.737 150.949 130.853 151.488C130.471 151.721 129.973 151.6 129.74 151.217C129.517 150.852 129.618 150.38 129.961 150.135C130.32 149.662 130.694 147.106 131.024 144.848C131.314 142.871 131.642 140.63 132.113 138.245C132.298 137.311 132.497 136.205 132.728 134.925C134.077 127.447 136.116 116.147 140.239 109.999C140.293 109.922 140.339 109.855 140.39 109.782C141.14 108.692 142.897 106.139 146.846 106.139H162.641C166.591 106.139 168.348 108.692 169.098 109.782C169.148 109.855 169.194 109.922 169.236 109.981C173.372 116.148 175.411 127.45 176.76 134.929C176.99 136.208 177.19 137.312 177.374 138.245C177.846 140.63 178.174 142.871 178.463 144.848C178.794 147.106 179.167 149.662 179.526 150.136C179.869 150.38 179.97 150.852 179.748 151.217C179.596 151.467 179.329 151.606 179.055 151.606Z"
        fill="#444445"
      />
      <path
        d="M129.202 214.679C128.55 214.537 128.133 213.888 128.275 213.235L133.917 185.997L140.066 159.059C140.208 158.407 140.858 157.989 141.51 158.131C142.162 158.273 142.579 158.923 142.438 159.575L136.835 186.632L130.646 213.751C130.504 214.403 129.854 214.821 129.202 214.679Z"
        fill="#F9F8F3"
      />
      <path
        d="M129.752 215.579C129.604 215.579 129.455 215.563 129.306 215.531C128.764 215.413 128.299 215.09 127.998 214.62C127.696 214.151 127.595 213.593 127.713 213.051L133.353 185.82L139.507 158.866C139.623 158.331 139.947 157.866 140.416 157.565C140.885 157.263 141.443 157.162 141.985 157.281C142.528 157.398 142.993 157.722 143.294 158.191C143.596 158.66 143.697 159.218 143.579 159.761L137.978 186.809L131.785 213.946C131.669 214.48 131.345 214.945 130.876 215.247C130.535 215.466 130.147 215.579 129.752 215.579ZM141.543 158.976C141.459 158.976 141.393 159.008 141.358 159.031C141.312 159.06 141.233 159.126 141.208 159.245L135.056 186.191L129.418 213.413C129.39 213.541 129.435 213.634 129.464 213.679C129.493 213.724 129.559 213.803 129.678 213.829L129.678 213.829C129.795 213.853 129.89 213.81 129.935 213.781C129.98 213.752 130.059 213.686 130.085 213.567L136.276 186.439L141.875 159.399C141.916 159.21 141.796 159.023 141.615 158.984C141.59 158.978 141.566 158.976 141.543 158.976Z"
        fill="#444445"
      />
      <path
        d="M180.267 214.679C180.919 214.537 181.336 213.888 181.195 213.235L175.553 185.997L169.403 159.059C169.261 158.407 168.612 157.989 167.959 158.131C167.307 158.273 166.89 158.923 167.032 159.575L172.634 186.632L178.823 213.751C178.965 214.403 179.615 214.821 180.267 214.679Z"
        fill="#F9F8F3"
      />
      <path
        d="M179.716 215.578C179.321 215.578 178.933 215.465 178.592 215.246C178.123 214.945 177.799 214.479 177.681 213.937L171.494 186.826L165.888 159.752C165.772 159.218 165.872 158.66 166.174 158.191C166.475 157.722 166.941 157.398 167.484 157.28C168.027 157.163 168.583 157.263 169.053 157.564C169.522 157.866 169.845 158.331 169.963 158.874L176.111 185.802L181.757 213.059C181.873 213.593 181.772 214.15 181.471 214.62C181.169 215.089 180.704 215.412 180.162 215.53C180.013 215.562 179.864 215.578 179.716 215.578ZM167.925 158.975C167.903 158.975 167.879 158.977 167.854 158.982C167.735 159.008 167.668 159.088 167.64 159.132C167.611 159.178 167.566 159.271 167.592 159.389L173.196 186.455L179.382 213.557C179.41 213.685 179.489 213.751 179.534 213.78C179.579 213.809 179.673 213.853 179.791 213.828L179.791 213.827C179.91 213.802 179.976 213.722 180.005 213.678C180.034 213.632 180.079 213.54 180.053 213.421L174.409 186.173L168.262 159.253C168.235 159.125 168.156 159.059 168.111 159.03C168.075 159.007 168.01 158.975 167.925 158.975Z"
        fill="#444445"
      />
      <path
        d="M171.302 182.61C171.115 182.61 170.926 182.549 170.767 182.425L142.204 160.117C141.825 159.82 141.758 159.273 142.054 158.893C142.35 158.515 142.898 158.447 143.277 158.743L171.84 181.051C172.219 181.348 172.286 181.895 171.99 182.275C171.818 182.495 171.561 182.61 171.302 182.61Z"
        fill="#444445"
      />
      <path
        d="M138.167 182.61C137.908 182.61 137.651 182.494 137.48 182.275C137.183 181.895 137.251 181.348 137.63 181.051L166.192 158.743C166.571 158.447 167.119 158.515 167.415 158.893C167.712 159.273 167.644 159.82 167.265 160.117L138.703 182.425C138.543 182.549 138.354 182.61 138.167 182.61Z"
        fill="#444445"
      />
      <path
        d="M157.669 147.253H147.794C146.581 147.253 145.599 146.27 145.599 145.058V127.093C145.599 125.881 146.581 124.898 147.794 124.898H157.669C158.881 124.898 159.864 125.881 159.864 127.093V145.058C159.864 146.27 158.881 147.253 157.669 147.253Z"
        fill="#A4A6A8"
      />
      <path
        d="M157.669 147.862H147.793C146.248 147.862 144.99 146.604 144.99 145.059V127.094C144.99 125.549 146.248 124.291 147.793 124.291H157.669C159.214 124.291 160.472 125.549 160.472 127.094V145.059C160.472 146.604 159.214 147.862 157.669 147.862ZM147.793 125.508C146.919 125.508 146.207 126.22 146.207 127.094V145.059C146.207 145.933 146.919 146.645 147.793 146.645H157.668C158.543 146.645 159.255 145.933 159.255 145.059V127.094C159.255 126.219 158.543 125.508 157.668 125.508H147.793Z"
        fill="#424243"
      />
      <path
        d="M155.701 133.88C153.764 133.88 152.764 132.432 152.694 130.932C151.064 130.951 149.748 132.277 149.748 133.911C149.748 135.558 151.083 136.893 152.73 136.893C154.376 136.893 155.711 135.558 155.711 133.911C155.711 133.901 155.71 133.89 155.71 133.88C155.707 133.879 155.704 133.88 155.701 133.88Z"
        fill="#59595B"
      />
      <path
        d="M171.146 158.115C159.505 154.388 148.106 154.099 137.008 158.115C132.469 158.115 128.789 153.182 128.789 147.096C128.789 141.01 132.469 136.076 137.008 136.076H171.146C175.685 136.076 179.365 141.01 179.365 147.096C179.365 153.182 175.685 158.115 171.146 158.115Z"
        fill="black"
      />
      <path
        d="M171.146 158.928H171.019L170.899 158.889C159.055 155.097 147.745 155.094 137.285 158.88L137.151 158.928H137.009C132.029 158.928 127.978 153.62 127.978 147.097C127.978 140.573 132.029 135.266 137.008 135.266H171.146C176.125 135.266 180.177 140.573 180.177 147.097C180.177 153.62 176.125 158.928 171.146 158.928ZM153.557 154.418C159.304 154.418 165.219 155.379 171.268 157.303C175.297 157.213 178.554 152.669 178.554 147.097C178.554 141.468 175.231 136.889 171.146 136.889H137.008C132.924 136.889 129.6 141.468 129.6 147.097C129.6 152.663 132.85 157.203 136.872 157.303C142.236 155.38 147.812 154.418 153.557 154.418Z"
        fill="#444445"
      />
      <path
        d="M158.018 147.884H150.175C137.933 147.884 127.916 137.868 127.916 125.626V112.188C127.916 99.9463 137.933 89.9297 150.175 89.9297H158.018C170.26 89.9297 180.276 99.9459 180.276 112.188V125.626C180.276 137.868 170.26 147.884 158.018 147.884Z"
        fill="#B6872C"
      />
      <path
        d="M158.018 148.494H150.175C137.566 148.494 127.308 138.236 127.308 125.627V112.19C127.308 99.5806 137.566 89.3223 150.175 89.3223H158.018C170.627 89.3223 180.885 99.5802 180.885 112.19V125.627C180.885 138.236 170.627 148.494 158.018 148.494ZM150.175 90.5398C138.237 90.5398 128.525 100.252 128.525 112.19V125.627C128.525 137.565 138.237 147.277 150.175 147.277H158.018C169.956 147.277 179.668 137.565 179.668 125.627V112.19C179.668 100.252 169.956 90.5398 158.018 90.5398H150.175Z"
        fill="#444445"
      />
      <path
        d="M180.277 116.121H127.917V121.575H180.277V116.121Z"
        fill="#CECFD1"
      />
      <path
        d="M180.277 122.512H127.917C127.399 122.512 126.979 122.092 126.979 121.574V116.12C126.979 115.602 127.399 115.182 127.917 115.182H180.277C180.795 115.182 181.215 115.602 181.215 116.12V121.574C181.216 122.092 180.795 122.512 180.277 122.512ZM128.855 120.635H179.339V117.058H128.855V120.635Z"
        fill="#444445"
      />
      <path
        d="M168.699 95.3259C168.682 101.635 162.263 106.732 154.362 106.711L154.086 106.71C146.185 106.689 139.794 101.558 139.811 95.2488L139.872 72.184C139.889 65.8751 146.308 60.7776 154.209 60.7989L154.485 60.7996C162.386 60.8208 168.778 65.9521 168.761 72.2611L168.699 95.3259Z"
        fill="#D4AC97"
      />
      <path
        d="M154.485 60.8008H154.479V106.71C162.327 106.681 168.683 101.605 168.7 95.3271L168.761 72.2623C168.778 65.9533 162.387 60.8217 154.485 60.8008Z"
        fill="#DBB79D"
      />
      <path
        d="M154.411 106.983C154.395 106.983 154.379 106.983 154.362 106.983L154.086 106.983C150.204 106.972 146.555 105.753 143.81 103.55C141.049 101.333 139.532 98.3853 139.541 95.2504L139.603 72.1857C139.62 65.7512 146.147 60.5312 154.161 60.5312C154.178 60.5312 154.194 60.5312 154.21 60.5312L154.486 60.5319C158.368 60.5422 162.018 61.761 164.761 63.9643C167.523 66.1815 169.039 69.1291 169.031 72.264L168.969 95.3288C168.952 101.763 162.426 106.983 154.411 106.983ZM154.161 61.0708C146.442 61.0708 140.159 66.049 140.142 72.1867L140.081 95.2514C140.073 98.219 141.517 101.017 144.148 103.129C146.797 105.256 150.327 106.433 154.087 106.443L154.364 106.443C154.379 106.443 154.396 106.443 154.412 106.443C162.13 106.443 168.413 101.465 168.43 95.3271L168.491 72.2624C168.499 69.2948 167.055 66.4972 164.424 64.3846C161.775 62.2581 158.245 61.0811 154.484 61.0711L154.209 61.0704C154.193 61.0708 154.176 61.0708 154.161 61.0708Z"
        fill="#444445"
      />
      <path
        d="M154.411 107.216L154.084 107.215C150.149 107.205 146.448 105.968 143.663 103.732C140.845 101.469 139.297 98.4558 139.306 95.2482L139.367 72.1834C139.385 65.6281 146.02 60.2949 154.16 60.2949L154.486 60.2959C158.421 60.3062 162.122 61.5436 164.908 63.7798C167.726 66.0424 169.273 69.0552 169.265 72.2631L169.203 95.3279C169.186 101.884 162.55 107.216 154.411 107.216ZM154.16 60.5297V60.7641C146.279 60.7641 139.853 65.8877 139.836 72.1847L139.775 95.2495C139.767 98.3117 141.251 101.194 143.957 103.366C146.659 105.535 150.257 106.736 154.086 106.747L154.411 106.748C162.292 106.748 168.718 101.624 168.735 95.3269L168.796 72.2621C168.804 69.1996 167.32 66.3174 164.614 64.146C161.912 61.9762 158.314 60.7757 154.485 60.7651L154.209 60.7644L154.16 60.5297ZM154.411 106.676L154.086 106.676C150.272 106.666 146.691 105.471 144.001 103.311C141.313 101.153 139.837 98.2901 139.845 95.2495L139.907 72.1847C139.924 65.9262 146.318 60.8348 154.16 60.8348L154.484 60.8355C158.297 60.8454 161.879 62.0407 164.569 64.2008C167.257 66.3589 168.733 69.2218 168.725 72.2618L168.664 95.0918L168.699 95.0921L168.698 95.5609H168.66C168.487 101.712 162.155 106.676 154.411 106.676ZM154.208 61.3039H154.16C146.576 61.3039 140.392 66.1855 140.376 72.1861L140.314 95.2509C140.307 98.1457 141.72 100.878 144.294 102.945C146.902 105.039 150.379 106.197 154.087 106.207L154.411 106.208C161.995 106.208 168.178 101.326 168.194 95.3256L168.256 72.2608C168.263 69.3663 166.85 66.6338 164.276 64.5666C161.668 62.473 158.19 61.3145 154.483 61.3046L154.208 61.3039Z"
        fill="#444445"
      />
      <path
        d="M167.416 89.1152V72.3431L154.492 71.9434C154.494 72.6648 154.495 79.0827 154.497 79.8042C154.277 79.8467 154.066 79.9253 153.876 80.0352C153.444 80.2856 153.129 80.7116 153.081 81.1754C153.034 81.6392 153.273 82.1283 153.709 82.374C154.144 82.6196 154.76 82.5874 155.135 82.2697V89.1155H167.416V89.1152Z"
        fill="#DBB79D"
      />
      <path
        d="M148.143 79.3775C148.142 79.9147 147.705 80.3486 147.168 80.3473C146.631 80.346 146.197 79.9094 146.198 79.3722C146.2 78.8353 146.636 78.401 147.173 78.4023C147.71 78.4037 148.144 78.8403 148.143 79.3775Z"
        fill="#444445"
      />
      <path
        d="M162.55 79.3775C162.549 79.9147 162.112 80.3486 161.575 80.3473C161.038 80.346 160.604 79.9094 160.605 79.3722C160.607 78.8353 161.043 78.401 161.58 78.4023C162.118 78.4037 162.552 78.8403 162.55 79.3775Z"
        fill="#444445"
      />
      <path
        d="M160.322 76.5464C160.335 76.5341 160.349 76.5225 160.362 76.5109C160.374 76.5006 160.386 76.4903 160.399 76.48C160.477 76.4156 160.322 76.5358 160.423 76.463C160.563 76.3614 160.718 76.2755 160.881 76.216C161.426 76.0181 161.961 76.1845 162.396 76.546C162.802 76.883 163.389 76.303 162.979 75.963C162.345 75.4361 161.601 75.1728 160.784 75.383C160.407 75.4799 160.021 75.6928 159.739 75.963C159.354 76.3319 159.938 76.9143 160.322 76.5464Z"
        fill="#444445"
      />
      <path
        d="M146.127 76.5464C146.14 76.5341 146.153 76.5225 146.167 76.5109C146.179 76.5006 146.191 76.4903 146.203 76.48C146.282 76.4156 146.127 76.5358 146.227 76.463C146.368 76.3614 146.523 76.2755 146.686 76.216C147.231 76.0181 147.766 76.1845 148.201 76.546C148.607 76.883 149.194 76.303 148.784 75.963C148.15 75.4361 147.405 75.1728 146.589 75.383C146.212 75.4799 145.826 75.6928 145.544 75.963C145.159 76.3319 145.743 76.9143 146.127 76.5464Z"
        fill="#444445"
      />
      <path
        d="M161.9 81.6211C160.531 81.6211 159.422 82.3505 159.422 83.2503C159.422 84.1501 160.531 84.8795 161.9 84.8795C163.268 84.8795 164.378 84.1501 164.378 83.2503C164.378 82.3505 163.268 81.6211 161.9 81.6211Z"
        fill="#AE7D77"
      />
      <path
        d="M147.116 84.8795C148.484 84.8795 149.593 84.1501 149.593 83.2503C149.593 82.3505 148.484 81.6211 147.116 81.6211C145.747 81.6211 144.638 82.3505 144.638 83.2503C144.638 84.1501 145.747 84.8795 147.116 84.8795Z"
        fill="#AE7D77"
      />
      <path
        d="M154.348 82.7671C154.086 82.7671 153.821 82.7047 153.595 82.5769C153.091 82.2923 152.792 81.7196 152.85 81.1519C152.904 80.6256 153.244 80.1332 153.76 79.8337C153.918 79.7424 154.087 79.6707 154.264 79.6203L154.259 78.1315C154.259 78.0027 154.363 77.8988 154.491 77.8984H154.492C154.62 77.8984 154.724 78.0024 154.724 78.1302L154.73 79.9958L154.541 80.032C154.346 80.0698 154.161 80.1382 153.993 80.2362C153.612 80.4573 153.351 80.8262 153.313 81.1987C153.273 81.5855 153.478 81.9762 153.823 82.1711C154.182 82.3743 154.693 82.3391 154.985 82.0921C155.083 82.0091 155.23 82.0214 155.313 82.119C155.396 82.2173 155.384 82.3637 155.286 82.4467C155.037 82.6579 154.694 82.7671 154.348 82.7671Z"
        fill="#444445"
      />
      <path
        d="M134.194 141.839L133.559 141.982C131.904 142.354 130.261 141.314 129.889 139.659L124.166 114.197C123.794 112.542 124.834 110.899 126.489 110.527L127.125 110.384C128.78 110.012 130.423 111.052 130.795 112.707L136.518 138.169C136.889 139.824 135.85 141.468 134.194 141.839Z"
        fill="#DBB79D"
      />
      <path
        d="M132.878 142.871C132.151 142.871 131.438 142.666 130.809 142.268C129.932 141.713 129.325 140.851 129.097 139.839L123.374 114.377C122.905 112.288 124.222 110.207 126.311 109.737L126.947 109.594C127.959 109.366 128.999 109.547 129.874 110.102C130.751 110.656 131.358 111.519 131.586 112.531L137.309 137.993C137.536 139.005 137.356 140.045 136.802 140.921C136.247 141.797 135.384 142.405 134.372 142.632L133.737 142.775C133.451 142.839 133.164 142.871 132.878 142.871ZM127.798 111.122C127.634 111.122 127.468 111.14 127.302 111.178L126.666 111.32C125.451 111.594 124.684 112.805 124.958 114.021L130.681 139.483C130.954 140.699 132.167 141.467 133.381 141.192L134.017 141.049C134.606 140.917 135.108 140.563 135.431 140.053C135.753 139.543 135.858 138.938 135.726 138.349L130.003 112.886C129.767 111.837 128.831 111.122 127.798 111.122Z"
        fill="#424243"
      />
      <path
        d="M133.519 99.0397C129.088 97.3195 125.612 100.424 124.249 104.171C123.564 106.052 123.19 108.083 123.19 110.201V125.287L133.405 125.285L133.519 99.0397Z"
        fill="#B6872C"
      />
      <path
        d="M132.99 112.348L132.936 124.815L123.661 124.817V110.202C123.661 108.186 124.007 106.211 124.69 104.333C125.833 101.193 128.524 99.385 131.9 99.385C132.274 99.385 132.656 99.4072 133.045 99.4524L133.04 101.652C133.039 101.913 133.251 102.124 133.511 102.124C133.771 102.124 133.981 101.915 133.982 101.656L133.989 99.043C133.99 98.8132 133.823 98.6117 133.596 98.5768C129.035 97.8716 125.281 99.9554 123.805 104.012C123.084 105.993 122.719 108.076 122.719 110.202V125.288C122.719 125.413 122.769 125.533 122.857 125.621C122.945 125.709 123.065 125.759 123.19 125.759L133.404 125.757C133.664 125.757 133.874 125.547 133.875 125.288L133.931 112.353C133.932 112.092 133.721 111.88 133.46 111.88C133.201 111.879 132.991 112.089 132.99 112.348Z"
        fill="#444445"
      />
      <path
        d="M123.19 125.992C123.002 125.992 122.825 125.919 122.691 125.786C122.558 125.653 122.484 125.475 122.484 125.287V110.202C122.484 108.048 122.855 105.939 123.585 103.93C125.102 99.7607 128.951 97.6202 133.632 98.344C133.971 98.3968 134.225 98.6976 134.223 99.0429L134.216 101.655C134.214 102.043 133.898 102.358 133.511 102.358C133.322 102.358 133.144 102.284 133.011 102.15C132.878 102.016 132.805 101.838 132.806 101.65L132.81 99.6624C129.105 99.3102 126.102 101.139 124.911 104.413C124.237 106.264 123.895 108.212 123.895 110.202V124.582L132.702 124.581L132.755 112.347C132.757 111.959 133.073 111.645 133.46 111.645C133.649 111.645 133.827 111.718 133.96 111.852C134.094 111.986 134.167 112.164 134.166 112.353L134.11 125.289C134.108 125.676 133.791 125.991 133.404 125.991L123.19 125.992ZM131.881 98.6757C128.209 98.6757 125.277 100.654 124.026 104.091C123.314 106.047 122.954 108.103 122.954 110.202V125.287C122.954 125.35 122.978 125.409 123.023 125.454C123.068 125.499 123.127 125.523 123.19 125.523L133.405 125.522C133.535 125.522 133.641 125.416 133.641 125.286L133.698 112.351C133.698 112.288 133.673 112.228 133.629 112.183C133.584 112.138 133.525 112.113 133.461 112.113C133.331 112.113 133.225 112.219 133.225 112.349L133.17 125.049L123.427 125.051V110.202C123.427 108.157 123.778 106.156 124.47 104.253C125.633 101.057 128.411 99.1498 131.9 99.1498C132.282 99.1498 132.677 99.1727 133.073 99.2185L133.281 99.2428L133.275 101.652C133.275 101.715 133.299 101.775 133.344 101.82C133.389 101.864 133.448 101.889 133.511 101.889C133.641 101.889 133.747 101.784 133.748 101.654L133.755 99.0416C133.755 98.9253 133.672 98.8251 133.56 98.8078C132.986 98.7192 132.425 98.6757 131.881 98.6757Z"
        fill="#444445"
      />
      <path
        d="M136.418 146.437C132.359 146.437 129.069 150.679 129.069 155.283V200.402H143.766L143.99 157.085C143.99 152.482 146.832 145.885 136.418 146.437Z"
        fill="black"
      />
      <path
        d="M144.626 201.267H128.205V155.284C128.205 150.03 131.953 145.589 136.394 145.574C139.959 145.386 142.374 146.007 143.766 147.465C145.553 149.337 145.271 152.152 145.023 154.636C144.936 155.507 144.854 156.329 144.854 157.087L144.626 201.267ZM129.934 199.538H142.906L143.126 157.082C143.126 156.243 143.216 155.338 143.303 154.464C143.525 152.248 143.754 149.956 142.516 148.658C141.504 147.599 139.467 147.141 136.463 147.301L136.417 147.302C132.964 147.302 129.934 151.032 129.934 155.284V199.538Z"
        fill="#444445"
      />
      <path
        d="M172.755 146.708C176.796 147.126 179.733 151.689 179.364 156.274L175.753 201.207L161.117 199.693L164.361 156.533C164.73 151.948 162.427 145.086 172.755 146.708Z"
        fill="black"
      />
      <path
        d="M176.543 202.157L160.192 200.465L163.499 156.467C163.56 155.707 163.544 154.88 163.527 154.004C163.479 151.503 163.424 148.669 165.359 146.982C166.867 145.667 169.324 145.296 172.867 145.85C177.277 146.322 180.646 151.121 180.226 156.343L176.543 202.157ZM162.042 198.919L174.962 200.256L178.502 156.204C178.843 151.96 176.116 147.924 172.666 147.566L172.62 147.561C169.643 147.093 167.581 147.337 166.495 148.285C165.168 149.442 165.212 151.744 165.255 153.971C165.272 154.85 165.29 155.761 165.222 156.602L162.042 198.919Z"
        fill="#444445"
      />
      <path
        d="M146.014 209.274H127.532C127.072 209.274 126.699 208.901 126.699 208.441V207.426C126.699 204.739 128.878 202.561 131.565 202.561H141.982C144.669 202.561 146.848 204.739 146.848 207.426V208.441C146.847 208.901 146.474 209.274 146.014 209.274Z"
        fill="#B9BABD"
      />
      <path
        d="M146.014 210.143H127.532C126.594 210.143 125.83 209.38 125.83 208.441V207.426C125.83 204.264 128.403 201.691 131.565 201.691H141.982C145.144 201.691 147.717 204.264 147.717 207.426V208.441C147.717 209.38 146.953 210.143 146.014 210.143ZM127.568 208.405H145.978V207.426C145.978 205.222 144.186 203.43 141.982 203.43H131.564C129.36 203.43 127.568 205.222 127.568 207.426V208.405Z"
        fill="#454446"
      />
      <path
        d="M177.264 209.723H158.846C158.368 209.723 157.98 209.283 157.98 208.741C157.98 205.575 160.242 201.99 163.033 201.99L173.077 203.009C175.867 203.009 178.129 205.576 178.129 208.741C178.129 209.283 177.742 209.723 177.264 209.723Z"
        fill="#B9BABD"
      />
      <path
        d="M177.264 210.591H158.845C157.889 210.591 157.111 209.761 157.111 208.741C157.111 204.379 160.238 201.121 163.033 201.121C163.062 201.121 163.092 201.122 163.12 201.126L173.122 202.14C176.366 202.168 178.998 205.119 178.998 208.741C178.998 209.761 178.22 210.591 177.264 210.591ZM158.88 208.853H177.229C177.242 208.835 177.26 208.798 177.26 208.741C177.26 206.059 175.384 203.877 173.077 203.877C173.047 203.877 173.018 203.876 172.989 203.873L162.993 202.859C160.757 202.897 158.849 206.109 158.849 208.741C158.849 208.798 158.868 208.835 158.88 208.853Z"
        fill="#454446"
      />
      <path
        d="M172.575 69.8738C172.544 69.6072 172.502 69.3449 172.445 69.0886C172.018 67.1659 171.276 65.3 170.058 63.7375C168.459 61.688 166.188 60.4057 163.973 59.325C161.162 57.9551 157.558 57.4664 156.138 57.319C151.631 56.8578 146.554 57.81 142.213 59.9392C139.863 61.0943 137.859 62.7537 136.421 64.7359C135.059 66.6111 134.508 68.7167 133.974 70.7523C133.559 72.3314 133.168 73.8235 132.43 75.1718C132.209 75.3488 131.806 75.6867 131.803 76.2575C131.798 76.892 132.307 77.2954 132.499 77.4471C133.085 77.9113 133.859 78.2323 134.737 78.3761C136.937 78.7356 139.067 78.0361 140.781 77.4737C143.727 76.5072 146.299 76.974 150.449 74.188C153.851 71.9041 154.097 65.384 161.092 69.3957C164.101 68.6009 165.147 69.2184 165.366 73.1292C165.435 74.3623 167.475 76.0496 168.563 76.6393C169.026 76.8903 169.516 77.0696 169.988 77.2984C170.421 77.5082 171.12 78.1536 171.626 78.0218C171.935 77.9411 172.065 77.5789 172.123 77.2645C172.21 76.7834 172.243 76.294 172.274 75.8059C172.396 73.9347 172.794 71.7876 172.575 69.8738Z"
        fill="#444445"
      />
      <path
        d="M141.919 142.105C141.781 145.108 139.331 147.435 136.447 147.303C134.422 147.209 132.646 145.87 131.885 144.009C130.881 141.554 131.946 139.246 133.219 137.129C133.82 136.128 134.495 135.063 135.58 134.629C135.653 135.289 135.676 135.954 135.647 136.617C137.404 136.372 138.892 136.561 140.235 137.842C141.386 138.939 141.992 140.522 141.919 142.105Z"
        fill="#DBB79D"
      />
      <path
        d="M136.685 148.12C136.594 148.12 136.502 148.118 136.411 148.113C134.105 148.008 132.033 146.517 131.134 144.317C130.002 141.547 131.133 139.025 132.523 136.712C133.102 135.749 133.895 134.429 135.279 133.876C135.514 133.783 135.78 133.803 135.997 133.933C136.214 134.064 136.358 134.288 136.386 134.54C136.43 134.931 136.457 135.327 136.466 135.723C137.818 135.656 139.374 135.9 140.795 137.255C142.11 138.509 142.815 140.291 142.73 142.142C142.653 143.805 141.958 145.342 140.772 146.468C139.645 147.539 138.203 148.12 136.685 148.12ZM134.847 136.18C134.52 136.562 134.222 137.036 133.914 137.548C132.559 139.803 131.822 141.713 132.636 143.703C133.297 145.321 134.808 146.416 136.485 146.493C137.657 146.547 138.782 146.121 139.654 145.292C140.535 144.455 141.051 143.31 141.109 142.068C141.171 140.706 140.635 139.346 139.676 138.43C138.666 137.467 137.531 137.174 135.759 137.422C135.521 137.456 135.28 137.381 135.102 137.219C134.923 137.057 134.827 136.824 134.837 136.583C134.842 136.449 134.846 136.314 134.847 136.18Z"
        fill="#424243"
      />
      <path
        d="M174.767 141.839L175.402 141.982C177.057 142.354 178.7 141.314 179.072 139.659L184.795 114.197C185.167 112.542 184.127 110.899 182.472 110.527L181.837 110.384C180.182 110.012 178.539 111.052 178.167 112.707L172.444 138.169C172.072 139.824 173.112 141.468 174.767 141.839Z"
        fill="#DBB79D"
      />
      <path
        d="M176.083 142.87C175.797 142.87 175.509 142.839 175.224 142.775L174.588 142.632C173.576 142.404 172.714 141.796 172.159 140.92C171.604 140.044 171.424 139.004 171.652 137.992L177.375 112.53C177.844 110.442 179.925 109.123 182.014 109.593L182.65 109.736C184.738 110.206 186.056 112.287 185.587 114.376L179.864 139.838C179.636 140.85 179.028 141.713 178.152 142.267C177.523 142.665 176.81 142.87 176.083 142.87ZM181.162 111.121C180.129 111.121 179.194 111.836 178.958 112.886L173.235 138.348C173.103 138.937 173.207 139.543 173.53 140.052C173.853 140.562 174.355 140.916 174.944 141.048L175.58 141.191C176.799 141.467 178.007 140.698 178.28 139.482L184.003 114.02C184.276 112.805 183.51 111.593 182.294 111.32L181.659 111.177C181.492 111.14 181.326 111.121 181.162 111.121Z"
        fill="#424243"
      />
      <path
        d="M174.838 99.0391C179.269 98.3575 182.744 100.424 184.108 104.171C184.793 106.052 185.166 108.083 185.166 110.2V125.286L174.951 125.285L174.838 99.0391Z"
        fill="#B6872C"
      />
      <path
        d="M175.367 112.348L175.42 124.815L184.696 124.817V110.202C184.696 108.186 184.349 106.211 183.666 104.333C182.523 101.193 179.832 99.385 176.456 99.385C176.082 99.385 175.7 99.4072 175.311 99.4524L175.316 100.613C175.317 100.874 175.106 101.086 174.845 101.086C174.586 101.086 174.375 100.877 174.374 100.617L174.367 99.043C174.366 98.8132 174.533 98.6117 174.76 98.5768C179.321 97.8716 183.075 99.9554 184.551 104.012C185.272 105.993 185.637 108.076 185.637 110.202V125.288C185.637 125.413 185.587 125.533 185.499 125.621C185.411 125.709 185.291 125.759 185.166 125.759L174.951 125.757C174.692 125.757 174.482 125.547 174.48 125.288L174.424 112.353C174.423 112.092 174.634 111.88 174.895 111.88C175.155 111.879 175.365 112.089 175.367 112.348Z"
        fill="#444445"
      />
      <path
        d="M185.166 125.992L174.951 125.99C174.564 125.99 174.248 125.675 174.246 125.289L174.19 112.352C174.189 112.163 174.262 111.986 174.395 111.852C174.529 111.718 174.707 111.644 174.895 111.644C175.283 111.644 175.599 111.959 175.6 112.346L175.653 124.58L184.461 124.581V110.201C184.461 108.212 184.119 106.264 183.445 104.412C182.253 101.139 179.253 99.311 175.545 99.6619L175.55 100.611C175.551 100.8 175.477 100.977 175.344 101.112C175.211 101.245 175.033 101.319 174.844 101.319C174.457 101.319 174.141 101.004 174.139 100.617L174.132 99.0424C174.13 98.6968 174.385 98.3963 174.723 98.3435C179.401 97.6214 183.253 99.7602 184.771 103.93C185.501 105.938 185.871 108.048 185.871 110.201V125.287C185.871 125.475 185.798 125.652 185.664 125.786C185.534 125.917 185.352 125.992 185.166 125.992ZM174.895 112.113C174.832 112.113 174.773 112.138 174.728 112.183C174.683 112.227 174.659 112.287 174.659 112.35L174.715 125.286C174.716 125.416 174.821 125.521 174.951 125.521L185.166 125.523C185.228 125.523 185.289 125.498 185.333 125.454C185.378 125.409 185.403 125.349 185.403 125.287V110.201C185.403 108.103 185.042 106.047 184.33 104.09C182.893 100.145 179.24 98.1177 174.796 98.8073C174.684 98.8246 174.601 98.9249 174.601 99.0407L174.608 100.615C174.609 100.745 174.715 100.851 174.845 100.851C174.908 100.851 174.967 100.826 175.012 100.781C175.057 100.736 175.081 100.677 175.081 100.613L175.075 99.2423L175.283 99.218C175.679 99.1722 176.073 99.1493 176.456 99.1493C179.945 99.1493 182.723 101.057 183.886 104.252C184.578 106.155 184.929 108.157 184.929 110.201V125.051L175.186 125.049L175.131 112.348C175.131 112.219 175.025 112.113 174.895 112.113Z"
        fill="#444445"
      />
      <path
        d="M152.194 88.174C152.236 88.174 152.279 88.181 152.322 88.1956C154.504 88.9539 156.205 88.2155 156.221 88.2085C156.419 88.1189 156.651 88.2092 156.739 88.4064C156.828 88.6043 156.739 88.8367 156.541 88.9247C156.46 88.9622 154.539 89.7949 152.065 88.9376C151.86 88.8663 151.752 88.6425 151.822 88.4376C151.879 88.2759 152.031 88.174 152.194 88.174Z"
        fill="#424243"
      />
      <path
        d="M165.445 149.149L158.321 133.352C157.847 132.299 158.32 131.049 159.372 130.575L166.613 127.31C167.666 126.835 168.915 127.308 169.39 128.36L176.513 144.158C176.988 145.21 176.515 146.46 175.463 146.935L168.222 150.2C167.169 150.675 165.92 150.201 165.445 149.149Z"
        fill="#A4A6A8"
      />
      <path
        d="M167.363 151.197C166.253 151.197 165.19 150.559 164.705 149.483L157.582 133.685C157.263 132.979 157.239 132.188 157.515 131.459C157.791 130.731 158.332 130.154 159.039 129.835L166.279 126.57C167.743 125.909 169.47 126.564 170.13 128.027L177.253 143.824C177.912 145.288 177.259 147.015 175.796 147.675L168.555 150.94C168.168 151.114 167.762 151.197 167.363 151.197ZM166.184 148.815C166.325 149.127 166.58 149.366 166.904 149.488C167.227 149.61 167.576 149.601 167.888 149.46L175.129 146.195C175.776 145.903 176.065 145.139 175.774 144.491L168.65 128.694C168.358 128.047 167.594 127.758 166.947 128.049L159.706 131.314C159.394 131.455 159.155 131.71 159.033 132.033C158.91 132.357 158.92 132.706 159.061 133.018L166.184 148.815Z"
        fill="#424243"
      />
      <path
        d="M167.042 142.105C167.18 145.108 169.63 147.435 172.514 147.303C174.539 147.209 176.316 145.87 177.077 144.009C178.08 141.554 177.015 139.246 175.742 137.129C175.141 136.128 174.466 135.063 173.381 134.629C173.308 135.289 173.285 135.954 173.314 136.617C171.557 136.372 170.069 136.561 168.726 137.842C167.575 138.939 166.969 140.522 167.042 142.105Z"
        fill="#DBB79D"
      />
      <path
        d="M172.275 148.121C170.758 148.121 169.316 147.54 168.189 146.469C167.003 145.342 166.307 143.806 166.231 142.143C166.145 140.291 166.85 138.51 168.165 137.256C169.586 135.9 171.143 135.657 172.494 135.723C172.503 135.327 172.53 134.932 172.574 134.54C172.602 134.288 172.746 134.064 172.963 133.934C173.18 133.804 173.446 133.782 173.681 133.876C175.065 134.429 175.858 135.749 176.437 136.712C177.828 139.025 178.959 141.547 177.827 144.317C176.927 146.517 174.856 148.008 172.55 148.114C172.458 148.119 172.366 148.121 172.275 148.121ZM167.852 142.068C167.91 143.311 168.426 144.456 169.307 145.293C170.179 146.121 171.299 146.548 172.476 146.493C174.153 146.416 175.664 145.321 176.325 143.703C177.138 141.713 176.402 139.803 175.047 137.548C174.739 137.037 174.441 136.563 174.114 136.18C174.115 136.315 174.118 136.45 174.124 136.584C174.134 136.825 174.037 137.057 173.859 137.219C173.681 137.381 173.439 137.457 173.202 137.423C171.429 137.174 170.294 137.468 169.285 138.431C168.325 139.347 167.789 140.706 167.852 142.068Z"
        fill="#424243"
      />
      <path
        d="M168.761 88.459H171.273C172.427 88.459 173.371 87.5147 173.371 86.361V76.8793C173.371 75.7255 172.427 74.7812 171.273 74.7812H168.761V88.459Z"
        fill="#E6AB4A"
      />
      <path
        d="M171.273 88.8658H168.761C168.537 88.8658 168.355 88.6842 168.355 88.46V74.7827C168.355 74.5586 168.537 74.377 168.761 74.377H171.273C172.654 74.377 173.777 75.4998 173.777 76.8803V86.362C173.777 87.7426 172.654 88.8658 171.273 88.8658ZM169.167 88.0543H171.273C172.207 88.0543 172.966 87.295 172.966 86.3617V76.88C172.966 75.9467 172.207 75.1877 171.273 75.1877H169.167V88.0543Z"
        fill="#424243"
      />
      <path
        d="M139.812 88.459H137.299C136.145 88.459 135.201 87.5147 135.201 86.361V76.8793C135.201 75.7255 136.145 74.7812 137.299 74.7812H139.812V88.459Z"
        fill="#E6AB4A"
      />
      <path
        d="M139.812 88.8658H137.299C135.919 88.8658 134.796 87.7426 134.796 86.362V76.8803C134.796 75.4998 135.919 74.377 137.299 74.377H139.812C140.036 74.377 140.217 74.5586 140.217 74.7827V88.46C140.217 88.6842 140.036 88.8658 139.812 88.8658ZM137.299 75.1881C136.366 75.1881 135.607 75.947 135.607 76.8803V86.362C135.607 87.2953 136.366 88.0547 137.299 88.0547H139.406V75.1884H137.299V75.1881Z"
        fill="#424243"
      />
      <path
        d="M168.762 74.78C168.762 74.78 169.673 60.7975 154.21 60.7975C138.747 60.7975 139.811 74.78 139.811 74.78H137.506C137.506 74.78 136.514 57.8027 154.096 57.8027C171.679 57.8027 171.067 74.78 171.067 74.78H168.762Z"
        fill="#DE902F"
      />
      <path
        d="M171.067 75.1865H168.762C168.65 75.1865 168.543 75.14 168.466 75.0583C168.389 74.9767 168.349 74.8668 168.357 74.7549C168.36 74.6981 168.673 69.0635 164.969 65.1161C162.533 62.5205 158.913 61.204 154.21 61.204C149.509 61.204 145.905 62.5188 143.499 65.1112C139.837 69.0572 140.212 74.6938 140.216 74.7502C140.224 74.8628 140.185 74.974 140.109 75.0567C140.032 75.1394 139.924 75.1865 139.811 75.1865H137.506C137.291 75.1865 137.113 75.0192 137.101 74.8047C137.083 74.4963 136.715 67.1936 141.523 62.0965C144.464 58.9788 148.694 57.3984 154.096 57.3984C159.5 57.3984 163.766 58.9798 166.774 62.0988C171.687 67.1926 171.483 74.4876 171.472 74.7957C171.464 75.0139 171.285 75.1865 171.067 75.1865ZM169.179 74.3754H170.661C170.629 72.805 170.232 66.8476 166.185 62.6566C163.336 59.7056 159.268 58.2092 154.097 58.2092C148.929 58.2092 144.899 59.7026 142.118 62.6479C138.156 66.8443 137.899 72.8179 137.904 74.3751H139.389C139.365 72.9165 139.564 68.1644 142.898 64.5653C145.465 61.7963 149.27 60.3926 154.209 60.3926C159.15 60.3926 162.971 61.797 165.567 64.567C168.941 68.1684 169.188 72.9228 169.179 74.3754Z"
        fill="#424243"
      />
      <path
        d="M154.096 119.517C148.587 119.517 144.264 117.679 141.246 114.055C133.899 105.229 136.781 89.0349 136.908 88.3496L138.105 88.5701C138.075 88.7321 135.201 104.893 142.184 113.278C144.959 116.61 148.967 118.3 154.096 118.3C159.236 118.3 163.272 116.604 166.091 113.258C173.169 104.86 170.495 88.7251 170.467 88.5631L171.666 88.3569C171.784 89.0412 174.461 105.216 167.022 114.043C163.96 117.675 159.612 119.517 154.096 119.517Z"
        fill="#444445"
      />
      <path
        d="M161.108 162.301C159.951 162.301 158.706 162.177 157.361 161.93C151.617 160.874 148.466 157.598 148.486 152.705C148.503 148.787 150.08 145.026 151.605 141.389C152.047 140.337 152.463 139.343 152.843 138.346C154.367 134.351 155.837 129.586 155.189 124.535C155.125 124.039 155.101 123.513 155.075 122.956C155.01 121.569 154.943 120.135 154.258 119.292L155.202 118.523C156.141 119.678 156.22 121.389 156.29 122.9C156.315 123.431 156.339 123.933 156.396 124.38C157.078 129.698 155.558 134.643 153.981 138.779C153.593 139.796 153.153 140.845 152.728 141.86C151.248 145.387 149.719 149.034 149.704 152.71C149.681 158.14 153.974 160.07 157.581 160.733C160.524 161.274 162.926 161.19 164.928 160.478C168.849 159.082 170.419 155.671 170.02 149.419L171.234 149.341C171.507 153.615 171.193 159.539 165.336 161.624C164.068 162.076 162.668 162.301 161.108 162.301Z"
        fill="#444445"
      />
      <path
        d="M192.469 122.433H128.952L128.417 122.252V80.2048C128.417 78.1898 129.809 76.541 131.511 76.541H189.529C191.231 76.541 192.623 78.1898 192.623 80.2048V122.121L192.469 122.433Z"
        fill="#F4F5F5"
      />
      <path
        d="M189.235 124.358H131.807C129.114 124.358 126.922 122.167 126.922 119.473V79.7443C126.922 77.051 129.114 74.8594 131.807 74.8594H189.235C191.928 74.8594 194.12 77.051 194.12 79.7443V119.473C194.12 122.167 191.928 124.358 189.235 124.358ZM131.807 77.8508C130.763 77.8508 129.913 78.7005 129.913 79.7443V119.473C129.913 120.517 130.763 121.367 131.807 121.367H189.235C190.279 121.367 191.128 120.517 191.128 119.473V79.7443C191.128 78.7001 190.279 77.8508 189.235 77.8508H131.807Z"
        fill="#444445"
      />
      <path
        d="M170.217 131.995H151.012L152.989 106.926H168.24L170.217 131.995Z"
        fill="#F4F5F5"
      />
      <path
        d="M170.218 132.99H151.013C150.735 132.99 150.469 132.874 150.281 132.67C150.092 132.466 149.998 132.193 150.02 131.916L151.997 106.847C152.038 106.329 152.47 105.93 152.99 105.93H168.241C168.761 105.93 169.193 106.329 169.234 106.847L171.211 131.916C171.233 132.193 171.138 132.466 170.949 132.67C170.761 132.874 170.496 132.99 170.218 132.99ZM152.09 130.998H169.141L167.321 107.922H153.91L152.09 130.998Z"
        fill="#444445"
      />
      <path
        d="M273.151 132.566H40.9443V136.314H273.151V132.566Z"
        fill="#BEAD94"
      />
      <path
        d="M273.15 137.34H40.9439C40.3768 137.34 39.917 136.881 39.917 136.313V132.566C39.917 131.999 40.3768 131.539 40.9439 131.539H273.15C273.717 131.539 274.177 131.999 274.177 132.566V136.313C274.177 136.881 273.717 137.34 273.15 137.34ZM41.9705 135.287H272.123V133.593H41.9705V135.287Z"
        fill="#444445"
      />
      <path
        d="M259.688 209.344L256.502 209.355L238.622 136.202L241.758 136.133L259.688 209.344Z"
        fill="#2D2E2A"
      />
      <path
        d="M256.422 210.563C255.949 210.563 255.537 210.239 255.425 209.78L237.545 136.627C237.471 136.324 237.538 136.005 237.728 135.758C237.917 135.511 238.208 135.363 238.52 135.356L241.656 135.287C242.128 135.288 242.562 135.602 242.676 136.07L260.606 209.281C260.681 209.587 260.611 209.91 260.417 210.158C260.224 210.406 259.927 210.551 259.612 210.552L256.426 210.563C256.425 210.563 256.423 210.563 256.422 210.563ZM239.843 137.381L257.227 208.507L258.301 208.503L240.877 137.359L239.843 137.381Z"
        fill="#444445"
      />
      <path
        d="M217.736 209.344L220.922 209.355L238.802 136.202L235.666 136.133L217.736 209.344Z"
        fill="#2D2E2A"
      />
      <path
        d="M220.843 210.563C220.842 210.563 220.84 210.563 220.839 210.563L217.653 210.552C217.339 210.551 217.042 210.406 216.848 210.158C216.654 209.91 216.584 209.587 216.659 209.281L234.589 136.07C234.704 135.602 235.134 135.288 235.609 135.287L238.745 135.356C239.057 135.363 239.348 135.511 239.537 135.758C239.727 136.005 239.794 136.324 239.72 136.627L221.84 209.779C221.728 210.24 221.316 210.563 220.843 210.563ZM218.964 208.503L220.037 208.506L237.422 137.381L236.388 137.359L218.964 208.503Z"
        fill="#444445"
      />
      <path
        d="M93.388 209.526L90.202 209.537L72.3223 136.384L75.4581 136.314L93.388 209.526Z"
        fill="#2D2E2A"
      />
      <path
        d="M51.4365 209.526L54.6225 209.537L72.5023 136.384L69.3664 136.314L51.4365 209.526Z"
        fill="#2D2E2A"
      />
      <path
        d="M90.2408 210.562C89.7677 210.562 89.356 210.239 89.2434 209.779L71.3634 136.627C71.2894 136.324 71.3568 136.005 71.5463 135.758C71.7356 135.511 72.0268 135.363 72.3382 135.357L75.4741 135.287C75.9449 135.288 76.3795 135.602 76.494 136.07L94.4239 209.281C94.4986 209.587 94.4292 209.91 94.2353 210.158C94.0417 210.406 93.7446 210.551 93.4302 210.552L90.2441 210.563C90.2431 210.562 90.2418 210.562 90.2408 210.562ZM73.662 137.381L91.0463 208.506L92.1194 208.503L74.6955 137.358L73.662 137.381Z"
        fill="#444445"
      />
      <path
        d="M54.6622 210.563C54.6612 210.563 54.6598 210.563 54.6585 210.563L51.4725 210.552C51.1581 210.551 50.8609 210.406 50.6673 210.158C50.4734 209.91 50.4037 209.587 50.4788 209.281L68.4083 136.07C68.5231 135.602 68.9521 135.288 69.4282 135.287L72.5644 135.356C72.8759 135.363 73.167 135.511 73.3563 135.758C73.5459 136.005 73.6129 136.324 73.5392 136.627L55.6592 209.779C55.547 210.24 55.1353 210.563 54.6622 210.563ZM52.7836 208.503L53.8567 208.506L71.241 137.381L70.2068 137.359L52.7836 208.503Z"
        fill="#444445"
      />
      <path
        d="M85.8002 193.41H58.5729C57.9932 193.41 57.5234 192.94 57.5234 192.36C57.5234 191.78 57.9932 191.311 58.5729 191.311H85.8002C86.3799 191.311 86.8497 191.78 86.8497 192.36C86.8497 192.94 86.3799 193.41 85.8002 193.41Z"
        fill="#444445"
      />
      <path
        d="M252.27 193.41H225.043C224.463 193.41 223.993 192.94 223.993 192.36C223.993 191.78 224.463 191.311 225.043 191.311H252.27C252.85 191.311 253.319 191.78 253.319 192.36C253.319 192.94 252.85 193.41 252.27 193.41Z"
        fill="#444445"
      />
      <path
        d="M93.7662 104.836H76.8623V130.927H93.7662V104.836Z"
        fill="#BEAD94"
      />
      <path
        d="M76.3662 131.531V112.461H46.6498V131.531H76.3662Z"
        fill="#F9F8F3"
      />
      <path
        d="M94.0846 132.276H76.7009C76.092 132.276 75.5986 131.783 75.5986 131.174V104.423C75.5986 103.814 76.092 103.32 76.7009 103.32H94.0843C94.6932 103.32 95.1866 103.814 95.1866 104.423V131.174C95.1869 131.783 94.6935 132.276 94.0846 132.276ZM77.8035 130.071H92.9823V105.525H77.8035V130.071Z"
        fill="#444445"
      />
      <path
        d="M91.9425 129.474H78.9165C78.6356 129.474 78.4082 129.247 78.4082 128.966V106.565C78.4082 106.284 78.6356 106.057 78.9165 106.057H91.9425C92.2234 106.057 92.4508 106.284 92.4508 106.565V128.966C92.4508 129.247 92.223 129.474 91.9425 129.474ZM79.4248 128.458H91.4342V107.074H79.4248V128.458Z"
        fill="#444445"
      />
      <path
        d="M76.4425 132.818H46.4871C45.8781 132.818 45.3848 132.325 45.3848 131.716V112.251C45.3848 111.642 45.8781 111.148 46.4871 111.148H76.4425C77.0514 111.148 77.5448 111.642 77.5448 112.251V131.716C77.5448 132.324 77.0514 132.818 76.4425 132.818ZM47.5897 130.613H75.3402V113.353H47.5897V130.613Z"
        fill="#444445"
      />
      <path
        d="M74.2474 130.026H48.7413C48.4531 130.026 48.2197 129.792 48.2197 129.504V114.41C48.2197 114.122 48.4531 113.889 48.7413 113.889H74.2474C74.5356 113.889 74.769 114.122 74.769 114.41V129.504C74.769 129.792 74.5356 130.026 74.2474 130.026ZM49.2629 128.982H73.7258V114.932H49.2629V128.982Z"
        fill="#444445"
      />
      <path
        d="M196.485 125.592V128.473H216.581V125.592H196.485Z"
        fill="#C1D38B"
      />
      <path
        d="M216.582 128.874H196.486C196.264 128.874 196.084 128.694 196.084 128.472V125.592C196.084 125.369 196.264 125.189 196.486 125.189H216.582C216.804 125.189 216.984 125.369 216.984 125.592V128.472C216.984 128.694 216.804 128.874 216.582 128.874ZM196.888 128.07H216.18V125.994H196.888V128.07Z"
        fill="#444445"
      />
      <path
        d="M197.662 128.601V131.312H216.581V128.601H197.662Z"
        fill="#B76C65"
      />
      <path
        d="M216.582 131.639H197.663C197.441 131.639 197.261 131.459 197.261 131.237V128.525C197.261 128.303 197.441 128.123 197.663 128.123H216.582C216.804 128.123 216.984 128.303 216.984 128.525V131.237C216.984 131.459 216.804 131.639 216.582 131.639ZM198.065 130.835H216.18V128.927H198.065V130.835Z"
        fill="#444445"
      />
      <path
        d="M212.608 119.038L211.044 121.037L224.991 131.947L226.555 129.948L212.608 119.038Z"
        fill="#F9F8F3"
      />
      <path
        d="M225.009 132.349C224.922 132.349 224.835 132.321 224.761 132.263L210.813 121.352C210.729 121.287 210.675 121.191 210.662 121.084C210.649 120.979 210.679 120.872 210.744 120.788L212.309 118.789C212.445 118.615 212.698 118.583 212.873 118.72L226.821 129.631C226.905 129.697 226.959 129.793 226.972 129.899C226.985 130.005 226.955 130.112 226.889 130.196L225.325 132.194C225.246 132.296 225.128 132.349 225.009 132.349ZM211.625 120.967L224.94 131.383L226.009 130.017L212.694 119.601L211.625 120.967Z"
        fill="#444445"
      />
      <path
        d="M52.6124 170.252C50.0038 165.553 47.2042 156.877 40.0961 157.023C32.2084 157.186 30.9985 165.23 28.2102 170.252C26.5621 173.221 24.8712 176.368 25.0953 179.681C25.3911 184.05 30.2996 187.093 34.8622 187.093H40.4112H45.9601C50.523 187.093 55.4315 184.05 55.727 179.681C55.9518 176.367 54.2609 173.221 52.6124 170.252Z"
        fill="#BAD387"
      />
      <path
        d="M54.1028 169.38C51.0323 163.765 48.3028 155.718 40.7359 155.31C37.1458 155.117 33.8933 156.735 31.724 159.548C29.6688 162.214 28.6867 165.442 27.2168 168.427C25.2702 172.379 22.4129 176.598 23.6115 181.238C24.6202 185.142 28.4798 187.789 32.2741 188.541C34.7337 189.028 37.3413 188.819 39.8351 188.819C41.7502 188.819 43.6656 188.83 45.5803 188.819C49.5004 188.794 53.5417 187.278 55.8914 184.007C59.2278 179.362 56.5262 173.76 54.1028 169.38ZM53.0437 182.087C51.3368 184.41 48.345 185.348 45.58 185.367C42.136 185.391 38.6873 185.39 35.2437 185.367C32.4793 185.349 29.4865 184.409 27.7796 182.087C25.2815 178.688 28.0236 174.233 29.7007 171.123C32.0035 166.852 33.2695 159.761 38.9579 158.842C45.8967 157.722 48.5283 166.379 51.1223 171.122C52.8305 174.209 55.5265 178.709 53.0437 182.087Z"
        fill="#424243"
      />
      <path
        d="M55.1559 192.377H25.6719V212.133H55.1559V192.377Z"
        fill="#EEEFEF"
      />
      <path
        d="M55.1541 210.406C46.5536 210.406 37.9531 210.406 29.3522 210.406C28.1247 210.406 26.8976 210.406 25.6702 210.406C26.2455 210.981 26.8206 211.556 27.396 212.132C27.396 205.547 27.396 198.961 27.396 192.376C26.8206 192.952 26.2455 193.527 25.6702 194.102C34.2707 194.102 42.8712 194.102 51.4721 194.102C52.6995 194.102 53.9267 194.102 55.1541 194.102C54.5788 193.527 54.0037 192.952 53.4283 192.376C53.4283 198.961 53.4283 205.547 53.4283 212.132C53.4283 214.353 56.88 214.356 56.88 212.132C56.88 205.547 56.88 198.961 56.88 192.376C56.88 191.443 56.0891 190.65 55.1541 190.65C46.5536 190.65 37.9531 190.65 29.3522 190.65C28.1247 190.65 26.8976 190.65 25.6702 190.65C24.7372 190.65 23.9443 191.441 23.9443 192.376C23.9443 198.961 23.9443 205.547 23.9443 212.132C23.9443 213.065 24.7349 213.858 25.6702 213.858C34.2707 213.858 42.8712 213.858 51.4721 213.858C52.6995 213.858 53.9267 213.858 55.1541 213.858C57.3753 213.858 57.379 210.406 55.1541 210.406Z"
        fill="#424243"
      />
      <path
        d="M38.6631 180.271C38.6631 184.225 38.6631 188.18 38.6631 192.134C38.6631 194.355 42.1147 194.359 42.1147 192.134C42.1147 188.18 42.1147 184.225 42.1147 180.271C42.1147 178.05 38.6631 178.046 38.6631 180.271Z"
        fill="#424243"
      />
      <path
        d="M276.169 194.841C275.775 194.841 275.457 194.347 275.457 193.735V153.227C275.457 152.616 275.775 152.121 276.169 152.121C276.562 152.121 276.88 152.616 276.88 153.227V193.735C276.88 194.347 276.562 194.841 276.169 194.841Z"
        fill="#424243"
      />
      <path
        d="M277.029 175.251C277.029 175.251 287.698 175.408 288.625 164.783C288.625 164.783 278.598 165.508 277.029 175.251Z"
        fill="#BAD387"
      />
      <path
        d="M277.028 176.285C277.02 176.285 277.016 176.285 277.014 176.285C276.715 176.281 276.433 176.146 276.241 175.918C276.048 175.689 275.966 175.388 276.013 175.093C277.693 164.666 288.441 163.77 288.55 163.763C288.863 163.74 289.146 163.852 289.357 164.066C289.569 164.281 289.675 164.577 289.649 164.878C288.666 176.144 277.487 176.285 277.028 176.285ZM287.422 166.012C285.001 166.52 279.878 168.276 278.316 174.14C280.825 173.827 286.228 172.407 287.422 166.012Z"
        fill="#424243"
      />
      <path
        d="M267.227 173.334C267.227 173.334 266.892 182.283 275.789 183.264C275.789 183.264 275.373 174.836 267.227 173.334Z"
        fill="#BAD387"
      />
      <path
        d="M275.789 184.132C275.758 184.132 275.726 184.13 275.695 184.127C266.11 183.071 266.36 173.404 266.365 173.307C266.374 173.056 266.492 172.822 266.688 172.665C266.883 172.509 267.137 172.447 267.384 172.49C276.101 174.098 276.647 183.135 276.652 183.226C276.664 183.479 276.565 183.724 276.381 183.898C276.22 184.049 276.007 184.132 275.789 184.132ZM268.14 174.441C268.355 176.552 269.443 181.112 274.785 182.237C274.404 180.195 273.03 175.863 268.14 174.441Z"
        fill="#424243"
      />
      <path
        d="M265.868 157.268C265.868 157.268 265.484 167.509 275.667 168.632C275.667 168.632 275.19 158.987 265.868 157.268Z"
        fill="#BAD387"
      />
      <path
        d="M275.666 169.62C275.63 169.62 275.593 169.618 275.557 169.614C264.588 168.404 264.874 157.341 264.879 157.23C264.89 156.943 265.025 156.675 265.249 156.496C265.473 156.316 265.764 156.245 266.046 156.295C276.023 158.135 276.647 168.48 276.653 168.584C276.667 168.873 276.553 169.153 276.343 169.352C276.159 169.525 275.915 169.62 275.666 169.62ZM266.911 158.527C267.157 160.944 268.402 166.163 274.516 167.449C274.081 165.114 272.507 160.155 266.911 158.527Z"
        fill="#424243"
      />
      <path
        d="M276.163 158.838C276.163 158.838 281.41 152.261 275.232 146.555C275.232 146.555 270.845 153.157 276.163 158.838Z"
        fill="#BAD387"
      />
      <path
        d="M276.162 159.659C275.938 159.659 275.724 159.566 275.57 159.402C269.879 153.323 274.509 146.187 274.556 146.115C274.688 145.918 274.899 145.787 275.136 145.759C275.375 145.727 275.608 145.807 275.783 145.969C282.437 152.115 276.854 159.281 276.796 159.353C276.65 159.537 276.43 159.648 276.194 159.658C276.184 159.659 276.173 159.659 276.162 159.659ZM275.415 147.905C274.564 149.66 273.185 153.702 276.087 157.506C277.103 155.79 278.826 151.736 275.415 147.905Z"
        fill="#424243"
      />
      <path
        d="M276.326 161.129C276.326 161.129 284.695 160.264 284.436 151.857C284.436 151.857 276.652 153.354 276.326 161.129Z"
        fill="#BAD387"
      />
      <path
        d="M276.327 161.935C276.122 161.935 275.922 161.856 275.771 161.714C275.6 161.553 275.506 161.325 275.516 161.089C275.864 152.77 284.199 151.072 284.284 151.055C284.518 151.012 284.758 151.07 284.944 151.219C285.129 151.367 285.24 151.59 285.248 151.827C285.527 160.882 276.501 161.921 276.41 161.931C276.382 161.933 276.355 161.935 276.327 161.935ZM283.608 152.922C281.76 153.544 277.911 155.394 277.232 160.13C279.167 159.652 283.266 158.04 283.608 152.922Z"
        fill="#424243"
      />
      <path
        d="M284.643 190.918H268.827V207.337H284.643V190.918Z"
        fill="#EEEFEF"
      />
      <path
        d="M284.645 206.703C280.031 206.703 275.418 206.703 270.804 206.703C270.145 206.703 269.487 206.703 268.829 206.703C268.982 206.919 269.135 207.134 269.289 207.35C269.289 201.877 269.289 196.404 269.289 190.931C269.135 191.146 268.982 191.362 268.829 191.578C273.442 191.578 278.056 191.578 282.669 191.578C283.328 191.578 283.986 191.578 284.645 191.578C284.491 191.362 284.338 191.146 284.184 190.931C284.184 196.404 284.184 201.877 284.184 207.35C284.184 208.183 285.104 208.184 285.104 207.35C285.104 201.877 285.104 196.404 285.104 190.931C285.104 190.581 284.894 190.283 284.645 190.283C280.031 190.283 275.417 190.283 270.803 190.283C270.145 190.283 269.487 190.283 268.828 190.283C268.58 190.283 268.368 190.58 268.368 190.931C268.368 196.404 268.368 201.877 268.368 207.35C268.368 207.7 268.579 207.998 268.828 207.998C273.442 207.998 278.056 207.998 282.669 207.998C283.327 207.998 283.986 207.998 284.644 207.998C285.237 207.998 285.238 206.703 284.645 206.703Z"
        fill="#424243"
      />
      <path
        d="M286.575 187.748H266.892V190.96H286.575V187.748Z"
        fill="#EEEFEF"
      />
      <path
        d="M286.574 190.31C280.829 190.31 275.084 190.31 269.34 190.31C268.523 190.31 267.707 190.31 266.891 190.31C267.044 190.526 267.197 190.742 267.351 190.957C267.351 189.886 267.351 188.816 267.351 187.745C267.197 187.961 267.044 188.177 266.891 188.393C272.635 188.393 278.38 188.393 284.125 188.393C284.941 188.393 285.758 188.393 286.574 188.393C286.421 188.177 286.267 187.961 286.114 187.745C286.114 188.816 286.114 189.887 286.114 190.957C286.114 191.79 287.034 191.791 287.034 190.957C287.034 189.886 287.034 188.816 287.034 187.745C287.034 187.395 286.823 187.098 286.574 187.098C280.83 187.098 275.085 187.098 269.34 187.098C268.524 187.098 267.707 187.098 266.891 187.098C266.642 187.098 266.431 187.394 266.431 187.745C266.431 188.816 266.431 189.887 266.431 190.957C266.431 191.307 266.642 191.604 266.891 191.604C272.636 191.604 278.38 191.604 284.125 191.604C284.942 191.604 285.758 191.604 286.574 191.604C287.166 191.604 287.167 190.31 286.574 190.31Z"
        fill="#424243"
      />
    </svg>
  );
}
