import React from "react";

export default function SideCircle() {
  return (
    <svg
      width="359"
      height="141"
      viewBox="0 0 359 141"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0714 133.679C14.0714 129.793 10.9214 126.643 7.03571 126.643C3.15 126.643 0 129.793 0 133.679C0 137.564 3.15 140.714 7.03571 140.714C10.9214 140.714 14.0714 137.564 14.0714 133.679Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M49.25 126.643C53.1357 126.643 56.2857 129.793 56.2857 133.679C56.2857 137.564 53.1357 140.714 49.25 140.714C45.3643 140.714 42.2143 137.564 42.2143 133.679C42.2143 129.793 45.3643 126.643 49.25 126.643Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M98.5 133.679C98.5 129.793 95.35 126.643 91.4643 126.643C87.5786 126.643 84.4286 129.793 84.4286 133.679C84.4286 137.564 87.5786 140.714 91.4643 140.714C95.35 140.714 98.5 137.564 98.5 133.679Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M133.679 126.643C137.564 126.643 140.714 129.793 140.714 133.679C140.714 137.564 137.564 140.714 133.679 140.714C129.793 140.714 126.643 137.564 126.643 133.679C126.643 129.793 129.793 126.643 133.679 126.643Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M182.929 133.679C182.929 129.793 179.779 126.643 175.893 126.643C172.007 126.643 168.857 129.793 168.857 133.679C168.857 137.564 172.007 140.714 175.893 140.714C179.779 140.714 182.929 137.564 182.929 133.679Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M218.107 126.643C221.993 126.643 225.143 129.793 225.143 133.679C225.143 137.564 221.993 140.714 218.107 140.714C214.221 140.714 211.071 137.564 211.071 133.679C211.071 129.793 214.221 126.643 218.107 126.643Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M267.357 133.679C267.357 129.793 264.207 126.643 260.321 126.643C256.436 126.643 253.286 129.793 253.286 133.679C253.286 137.564 256.436 140.714 260.321 140.714C264.207 140.714 267.357 137.564 267.357 133.679Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M302.536 126.643C306.421 126.643 309.571 129.793 309.571 133.679C309.571 137.564 306.421 140.714 302.536 140.714C298.65 140.714 295.5 137.564 295.5 133.679C295.5 129.793 298.65 126.643 302.536 126.643Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M351.786 133.679C351.786 129.793 348.636 126.643 344.75 126.643C340.864 126.643 337.714 129.793 337.714 133.679C337.714 137.564 340.864 140.714 344.75 140.714C348.636 140.714 351.786 137.564 351.786 133.679Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M386.964 126.643C390.85 126.643 394 129.793 394 133.679C394 137.564 390.85 140.714 386.964 140.714C383.079 140.714 379.929 137.564 379.929 133.679C379.929 129.793 383.079 126.643 386.964 126.643Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M14.0714 91.4644C14.0714 87.5786 10.9214 84.4286 7.03571 84.4286C3.15 84.4286 0 87.5786 0 91.4644C0 95.3501 3.15 98.5001 7.03571 98.5001C10.9214 98.5001 14.0714 95.3501 14.0714 91.4644Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M49.25 84.4286C53.1357 84.4286 56.2857 87.5786 56.2857 91.4644C56.2857 95.3501 53.1357 98.5001 49.25 98.5001C45.3643 98.5001 42.2143 95.3501 42.2143 91.4644C42.2143 87.5786 45.3643 84.4286 49.25 84.4286Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M98.5 91.4644C98.5 87.5786 95.35 84.4286 91.4643 84.4286C87.5786 84.4286 84.4286 87.5786 84.4286 91.4644C84.4286 95.3501 87.5786 98.5001 91.4643 98.5001C95.35 98.5001 98.5 95.3501 98.5 91.4644Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M133.679 84.4286C137.564 84.4286 140.714 87.5786 140.714 91.4644C140.714 95.3501 137.564 98.5001 133.679 98.5001C129.793 98.5001 126.643 95.3501 126.643 91.4644C126.643 87.5786 129.793 84.4286 133.679 84.4286Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M182.929 91.4644C182.929 87.5786 179.779 84.4286 175.893 84.4286C172.007 84.4286 168.857 87.5786 168.857 91.4644C168.857 95.3501 172.007 98.5001 175.893 98.5001C179.779 98.5001 182.929 95.3501 182.929 91.4644Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M218.107 84.4286C221.993 84.4286 225.143 87.5786 225.143 91.4644C225.143 95.3501 221.993 98.5001 218.107 98.5001C214.221 98.5001 211.071 95.3501 211.071 91.4644C211.071 87.5786 214.221 84.4286 218.107 84.4286Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M267.357 91.4644C267.357 87.5786 264.207 84.4286 260.321 84.4286C256.436 84.4286 253.286 87.5786 253.286 91.4644C253.286 95.3501 256.436 98.5001 260.321 98.5001C264.207 98.5001 267.357 95.3501 267.357 91.4644Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M302.536 84.4286C306.421 84.4286 309.571 87.5786 309.571 91.4644C309.571 95.3501 306.421 98.5001 302.536 98.5001C298.65 98.5001 295.5 95.3501 295.5 91.4644C295.5 87.5786 298.65 84.4286 302.536 84.4286Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M351.786 91.4644C351.786 87.5786 348.636 84.4286 344.75 84.4286C340.864 84.4286 337.714 87.5786 337.714 91.4644C337.714 95.3501 340.864 98.5001 344.75 98.5001C348.636 98.5001 351.786 95.3501 351.786 91.4644Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M386.964 84.4286C390.85 84.4286 394 87.5786 394 91.4644C394 95.3501 390.85 98.5001 386.964 98.5001C383.079 98.5001 379.929 95.3501 379.929 91.4644C379.929 87.5786 383.079 84.4286 386.964 84.4286Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M14.0714 49.2501C14.0714 45.3643 10.9214 42.2144 7.03571 42.2144C3.15 42.2144 0 45.3643 0 49.2501C0 53.1358 3.15 56.2858 7.03571 56.2858C10.9214 56.2858 14.0714 53.1358 14.0714 49.2501Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M49.25 42.2144C53.1357 42.2144 56.2857 45.3643 56.2857 49.2501C56.2857 53.1358 53.1357 56.2858 49.25 56.2858C45.3643 56.2858 42.2143 53.1358 42.2143 49.2501C42.2143 45.3643 45.3643 42.2144 49.25 42.2144Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M98.5 49.2501C98.5 45.3643 95.35 42.2144 91.4643 42.2144C87.5786 42.2144 84.4286 45.3643 84.4286 49.2501C84.4286 53.1358 87.5786 56.2858 91.4643 56.2858C95.35 56.2858 98.5 53.1358 98.5 49.2501Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M133.679 42.2144C137.564 42.2144 140.714 45.3643 140.714 49.2501C140.714 53.1358 137.564 56.2858 133.679 56.2858C129.793 56.2858 126.643 53.1358 126.643 49.2501C126.643 45.3643 129.793 42.2144 133.679 42.2144Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M182.929 49.2501C182.929 45.3643 179.779 42.2144 175.893 42.2144C172.007 42.2144 168.857 45.3643 168.857 49.2501C168.857 53.1358 172.007 56.2858 175.893 56.2858C179.779 56.2858 182.929 53.1358 182.929 49.2501Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M218.107 42.2144C221.993 42.2144 225.143 45.3643 225.143 49.2501C225.143 53.1358 221.993 56.2858 218.107 56.2858C214.221 56.2858 211.071 53.1358 211.071 49.2501C211.071 45.3643 214.221 42.2144 218.107 42.2144Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M267.357 49.2501C267.357 45.3643 264.207 42.2144 260.321 42.2144C256.436 42.2144 253.286 45.3643 253.286 49.2501C253.286 53.1358 256.436 56.2858 260.321 56.2858C264.207 56.2858 267.357 53.1358 267.357 49.2501Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M302.536 42.2144C306.421 42.2144 309.571 45.3643 309.571 49.2501C309.571 53.1358 306.421 56.2858 302.536 56.2858C298.65 56.2858 295.5 53.1358 295.5 49.2501C295.5 45.3643 298.65 42.2144 302.536 42.2144Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M351.786 49.2501C351.786 45.3643 348.636 42.2144 344.75 42.2144C340.864 42.2144 337.714 45.3643 337.714 49.2501C337.714 53.1358 340.864 56.2858 344.75 56.2858C348.636 56.2858 351.786 53.1358 351.786 49.2501Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M386.964 42.2144C390.85 42.2144 394 45.3643 394 49.2501C394 53.1358 390.85 56.2858 386.964 56.2858C383.079 56.2858 379.929 53.1358 379.929 49.2501C379.929 45.3643 383.079 42.2144 386.964 42.2144Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M14.0714 7.0358C14.0714 3.15009 10.9214 6.10352e-05 7.03571 6.10352e-05C3.15 6.10352e-05 0 3.15009 0 7.0358C0 10.9215 3.15 14.0715 7.03571 14.0715C10.9214 14.0715 14.0714 10.9215 14.0714 7.0358Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M49.25 6.10352e-05C53.1357 6.10352e-05 56.2857 3.15009 56.2857 7.0358C56.2857 10.9215 53.1357 14.0715 49.25 14.0715C45.3643 14.0715 42.2143 10.9215 42.2143 7.0358C42.2143 3.15009 45.3643 6.10352e-05 49.25 6.10352e-05Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M98.5 7.0358C98.5 3.15009 95.35 6.10352e-05 91.4643 6.10352e-05C87.5786 6.10352e-05 84.4286 3.15009 84.4286 7.0358C84.4286 10.9215 87.5786 14.0715 91.4643 14.0715C95.35 14.0715 98.5 10.9215 98.5 7.0358Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M133.679 6.10352e-05C137.564 6.10352e-05 140.714 3.15009 140.714 7.0358C140.714 10.9215 137.564 14.0715 133.679 14.0715C129.793 14.0715 126.643 10.9215 126.643 7.0358C126.643 3.15009 129.793 6.10352e-05 133.679 6.10352e-05Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M182.929 7.0358C182.929 3.15009 179.779 6.10352e-05 175.893 6.10352e-05C172.007 6.10352e-05 168.857 3.15009 168.857 7.0358C168.857 10.9215 172.007 14.0715 175.893 14.0715C179.779 14.0715 182.929 10.9215 182.929 7.0358Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M218.107 6.10352e-05C221.993 6.10352e-05 225.143 3.15009 225.143 7.0358C225.143 10.9215 221.993 14.0715 218.107 14.0715C214.221 14.0715 211.071 10.9215 211.071 7.0358C211.071 3.15009 214.221 6.10352e-05 218.107 6.10352e-05Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M267.357 7.0358C267.357 3.15009 264.207 6.10352e-05 260.321 6.10352e-05C256.436 6.10352e-05 253.286 3.15009 253.286 7.0358C253.286 10.9215 256.436 14.0715 260.321 14.0715C264.207 14.0715 267.357 10.9215 267.357 7.0358Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M302.536 6.10352e-05C306.421 6.10352e-05 309.571 3.15009 309.571 7.0358C309.571 10.9215 306.421 14.0715 302.536 14.0715C298.65 14.0715 295.5 10.9215 295.5 7.0358C295.5 3.15009 298.65 6.10352e-05 302.536 6.10352e-05Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M351.786 7.0358C351.786 3.15009 348.636 6.10352e-05 344.75 6.10352e-05C340.864 6.10352e-05 337.714 3.15009 337.714 7.0358C337.714 10.9215 340.864 14.0715 344.75 14.0715C348.636 14.0715 351.786 10.9215 351.786 7.0358Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
      <path
        d="M386.964 6.10352e-05C390.85 6.10352e-05 394 3.15009 394 7.0358C394 10.9215 390.85 14.0715 386.964 14.0715C383.079 14.0715 379.929 10.9215 379.929 7.0358C379.929 3.15009 383.079 6.10352e-05 386.964 6.10352e-05Z"
        fill="#CEA461"
        fill-opacity="0.3"
      />
    </svg>
  );
}
