import React from "react";

export default function NotFound() {
  return (
    <svg
      width="390"
      height="174"
      viewBox="0 0 390 174"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M237.955 13.1186L191.967 0L186.289 30.4935L202.606 39.687L207.617 64.311L207.757 103.538L213.504 134.868L192.369 131.257L191.644 138.834L188.08 168.009L192.369 173.405L235.516 152.734L251.292 101.187L254.489 66.8989L237.955 13.1186Z"
        fill="black"
      />
      <path
        d="M165.68 64.3106L170.636 103.538L174.88 132.58L189.334 139.438L194.671 169.486L154.915 147.362L134.935 101.187L134.742 66.8985L149.221 15.6419L187.978 2.60645L191.564 29.1215L182.224 39.8891L165.68 64.3106Z"
        fill="#424140"
      />
      <path
        d="M96.5183 104.635L120.423 102.245L126.131 134.278L101.93 130.108L106.683 168.304L69.0805 162.63L66.152 131.613L3.75098 129.56L4.42739 109.265L26.9562 75.615L44.2855 37.7281L65.3987 2.19835L66.3733 3.19807L105.233 1.70557L110.251 52.7237L96.5183 104.635ZM26.6533 102.712L66.6479 102.259L60.1427 51.7381L63.7053 49.8077L65.6655 58.2448L26.6533 102.712Z"
        fill="black"
      />
      <path
        d="M122.435 99.5423L117.934 125.605L61.9396 130.772L1.51134 135.573L0 107.777L20.6392 71.5818L39.505 34.5439L67.4152 0.845703L63.972 50.883L32.6451 102.585L77.9161 108.196L122.435 99.5423Z"
        fill="#424140"
      />
      <path
        d="M359.62 104.635L383.526 102.245L389.233 134.278L365.03 130.108L369.784 168.304L332.181 162.63L329.253 131.613L266.852 129.56L267.528 109.265L290.057 75.615L307.386 37.7281L328.499 2.19835L329.474 3.19807L368.334 1.70557L373.351 52.7237L359.62 104.635ZM289.755 102.712L329.75 102.259L323.245 51.7381L326.807 49.8077L328.768 58.2448L289.755 102.712Z"
        fill="black"
      />
      <path
        d="M385.538 99.5423L381.035 125.605L325.041 130.772L264.614 135.573L263.103 107.777L283.74 71.5818L302.606 34.5439L330.516 0.845703L327.073 50.883L295.748 102.585L341.019 108.196L385.538 99.5423Z"
        fill="#424140"
      />
    </svg>
  );
}
