import React from "react";

export default function TranslateIcon() {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30.178 29.5597L26.7897 22.7988L23.4014 29.5597" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24.019 28.3564H29.5924" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M26.7899 34.8328C22.3566 34.8328 18.7466 31.2384 18.7466 26.7893C18.7466 22.3559 22.3407 18.7461 26.7899 18.7461C31.2232 18.7461 34.8332 22.3401 34.8332 26.7893C34.8332 31.2384 31.2391 34.8328 26.7899 34.8328Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.94817 3.16602H14.1548C17.4323 3.16602 19.0157 4.74938 18.9365 7.94771V14.1543C19.0157 17.4318 17.4323 19.0152 14.1548 18.936H7.94817C4.74984 18.9993 3.1665 17.416 3.1665 14.1385V7.93186C3.1665 4.74936 4.74984 3.16602 7.94817 3.16602Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.1665 23.749C3.1665 29.8765 8.12234 34.8324 14.2498 34.8324L12.5873 32.0615" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M34.8333 14.2493C34.8333 8.12185 29.8775 3.16602 23.75 3.16602L25.4125 5.93685" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.9904 16.5425C10.4544 16.5425 9.93039 16.4785 9.41839 16.3505C8.91439 16.2225 8.45839 16.0185 8.05039 15.7385C7.64239 15.4665 7.31839 15.1145 7.07839 14.6825C6.83839 14.2505 6.71839 13.7305 6.71839 13.1225C6.71839 12.4665 6.85039 11.8665 7.11439 11.3225C7.37839 10.7705 7.75039 10.2825 8.23039 9.85845C8.71039 9.43445 9.27839 9.08245 9.93439 8.80245C10.5984 8.51445 11.3224 8.31045 12.1064 8.19045L12.5024 9.77445C11.7984 9.90245 11.1864 10.0705 10.6664 10.2785C10.1544 10.4865 9.73039 10.7305 9.39439 11.0105C9.05839 11.2905 8.80639 11.5905 8.63839 11.9105C8.47839 12.2385 8.39839 12.5825 8.39839 12.9425C8.39839 13.2305 8.45039 13.4825 8.55439 13.6985C8.66639 13.9145 8.81439 14.0945 8.99839 14.2385C9.18239 14.3905 9.38639 14.5105 9.61039 14.5985C9.83439 14.6945 10.0664 14.7625 10.3064 14.8025C10.5464 14.8425 10.7744 14.8625 10.9904 14.8625C11.5424 14.8625 12.0544 14.8105 12.5264 14.7065C13.0064 14.6025 13.4264 14.4745 13.7864 14.3225L14.2664 15.8705C14.0744 15.9665 13.8024 16.0665 13.4504 16.1705C13.1064 16.2745 12.7184 16.3625 12.2864 16.4345C11.8624 16.5065 11.4304 16.5425 10.9904 16.5425ZM8.60239 9.89445C8.32239 9.78245 8.06239 9.62245 7.82239 9.41445C7.59039 9.20645 7.40239 8.95045 7.25839 8.64645C7.12239 8.33445 7.05439 7.97845 7.05439 7.57845C7.05439 7.07445 7.18639 6.63845 7.45039 6.27045C7.71439 5.90245 8.06639 5.61845 8.50639 5.41845C8.94639 5.21845 9.42639 5.11845 9.94639 5.11845C10.2024 5.11845 10.4384 5.13445 10.6544 5.16645C10.8784 5.19845 11.1064 5.24645 11.3384 5.31045L11.0744 6.90645C10.9064 6.86645 10.7344 6.83445 10.5584 6.81045C10.3824 6.78645 10.2264 6.77445 10.0904 6.77445C9.81039 6.77445 9.56639 6.81445 9.35839 6.89445C9.15839 6.97445 9.00239 7.09045 8.89039 7.24245C8.78639 7.38645 8.73439 7.55845 8.73439 7.75845C8.73439 7.91845 8.78239 8.07045 8.87839 8.21445C8.98239 8.35845 9.11439 8.48645 9.27439 8.59845C9.43439 8.70245 9.61039 8.79045 9.80239 8.86245C9.99439 8.92645 10.1824 8.96245 10.3664 8.97045L8.60239 9.89445Z" fill="black"/>
    </svg>
  );
}
