import React from "react";

export default function ArrowLeft(color: any) {
  return (
    <svg
      width="28"
      height="54"
      viewBox="0 0 28 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.1905 1.53395C25.0512 0.394933 23.2047 0.394933 22.0654 1.53395L2.68587 20.9137C-0.730304 24.3299 -0.731354 29.8681 2.68348 33.2855L21.847 52.4638C22.9859 53.603 24.8328 53.603 25.9717 52.4638C27.1107 51.3248 27.1107 49.478 25.9717 48.339L6.80327 29.1707C5.66426 28.0314 5.66426 26.1849 6.80327 25.0459L26.1905 5.65876C27.3294 4.51971 27.3294 2.673 26.1905 1.53395Z"
        fill="white"
      />
    </svg>
  );
}
