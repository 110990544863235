import React from "react";

export default function PublichesIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_411_5342)">
        <path
          d="M17.4622 1.52052H14.7189C14.5698 1.07909 14.152 0.760259 13.6609 0.760259H12.5007C12.1486 0.28676 11.5909 0 11 0C10.4091 0 9.85132 0.28676 9.49918 0.760259H8.33907C7.84794 0.760259 7.43018 1.07909 7.28108 1.52052H4.53778C3.71247 1.52052 3.04102 2.19197 3.04102 3.01728V20.5032C3.04102 21.3285 3.71247 22 4.53778 22H17.4622C18.2875 22 18.9589 21.3285 18.9589 20.5032V3.01728C18.9589 2.19197 18.2875 1.52052 17.4622 1.52052ZM8.33907 4.13391H13.6609C13.9946 4.13391 14.2944 3.98656 14.4991 3.75378H16.7257V19.7667H5.27428V3.75378H7.50084C7.70559 3.98656 8.00537 4.13391 8.33907 4.13391ZM8.33907 1.473H9.69034C9.81721 1.473 9.93452 1.40558 9.99834 1.29591C10.2109 0.930747 10.5853 0.712743 11 0.712743C11.4147 0.712743 11.7891 0.930747 12.0016 1.29586C12.0654 1.40553 12.1828 1.473 12.3096 1.473H13.6609C13.8836 1.473 14.0648 1.65418 14.0648 1.87689V3.01728C14.0648 3.23999 13.8836 3.42117 13.6609 3.42117H8.33907C8.11636 3.42117 7.93518 3.23999 7.93518 3.01728V1.87689C7.93518 1.65418 8.11636 1.473 8.33907 1.473ZM18.2462 20.5032C18.2462 20.9355 17.8945 21.2873 17.4622 21.2873H4.53778C4.10547 21.2873 3.75376 20.9355 3.75376 20.5032V3.01728C3.75376 2.58498 4.10547 2.23326 4.53778 2.23326H7.22244V3.01728C7.22244 3.02526 7.22287 3.0331 7.22306 3.04104H4.91791C4.72105 3.04104 4.56153 3.2006 4.56153 3.39741V20.1231C4.56153 20.3199 4.72105 20.4795 4.91791 20.4795H17.0821C17.2789 20.4795 17.4384 20.3199 17.4384 20.1231V3.39741C17.4384 3.2006 17.2789 3.04104 17.0821 3.04104H14.7769C14.7771 3.0331 14.7775 3.02526 14.7775 3.01728V2.23326H17.4622C17.8945 2.23326 18.2462 2.58498 18.2462 3.01728V20.5032Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M10.9999 2.23423C11.094 2.23423 11.1857 2.19621 11.2518 2.12969C11.3183 2.06364 11.3563 1.97146 11.3563 1.87786C11.3563 1.78425 11.3183 1.69207 11.2518 1.62602C11.1857 1.5595 11.094 1.52148 10.9999 1.52148C10.9058 1.52148 10.8141 1.5595 10.7481 1.62602C10.6816 1.69207 10.6436 1.78377 10.6436 1.87786C10.6436 1.97194 10.6816 2.06364 10.7481 2.12969C10.8141 2.19621 10.9058 2.23423 10.9999 2.23423Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M8.71916 7.55454H15.1814C15.3782 7.55454 15.5377 7.39498 15.5377 7.19817C15.5377 7.00136 15.3782 6.8418 15.1814 6.8418H8.71916C8.5223 6.8418 8.36279 7.00136 8.36279 7.19817C8.36279 7.39498 8.5223 7.55454 8.71916 7.55454Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M8.71916 11.3573H15.1814C15.3782 11.3573 15.5377 11.1977 15.5377 11.0009C15.5377 10.8041 15.3782 10.6445 15.1814 10.6445H8.71916C8.5223 10.6445 8.36279 10.8041 8.36279 11.0009C8.36279 11.1977 8.5223 11.3573 8.71916 11.3573Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M8.71916 15.1581H9.85955C10.0564 15.1581 10.2159 14.9985 10.2159 14.8017C10.2159 14.6049 10.0564 14.4453 9.85955 14.4453H8.71916C8.5223 14.4453 8.36279 14.6049 8.36279 14.8017C8.36279 14.9985 8.5223 15.1581 8.71916 15.1581Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M8.71916 9.45493H15.1814C15.3782 9.45493 15.5377 9.29537 15.5377 9.09856C15.5377 8.90175 15.3782 8.74219 15.1814 8.74219H8.71916C8.5223 8.74219 8.36279 8.90175 8.36279 9.09856C8.36279 9.29537 8.5223 9.45493 8.71916 9.45493Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M8.71916 13.2577H10.2397C10.4365 13.2577 10.5961 13.0981 10.5961 12.9013C10.5961 12.7045 10.4365 12.5449 10.2397 12.5449H8.71916C8.5223 12.5449 8.36279 12.7045 8.36279 12.9013C8.36279 13.0981 8.5223 13.2577 8.71916 13.2577Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M8.71916 17.0584H10.2397C10.4365 17.0584 10.5961 16.8989 10.5961 16.7021C10.5961 16.5053 10.4365 16.3457 10.2397 16.3457H8.71916C8.5223 16.3457 8.36279 16.5053 8.36279 16.7021C8.36279 16.8989 8.5223 17.0584 8.71916 17.0584Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M6.81853 7.55454H7.19866C7.39552 7.55454 7.55503 7.39498 7.55503 7.19817C7.55503 7.00136 7.39552 6.8418 7.19866 6.8418H6.81853C6.62167 6.8418 6.46216 7.00136 6.46216 7.19817C6.46216 7.39498 6.62167 7.55454 6.81853 7.55454Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M6.81853 11.3573H7.19866C7.39552 11.3573 7.55503 11.1977 7.55503 11.0009C7.55503 10.8041 7.39552 10.6445 7.19866 10.6445H6.81853C6.62167 10.6445 6.46216 10.8041 6.46216 11.0009C6.46216 11.1977 6.62167 11.3573 6.81853 11.3573Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M6.81853 15.1581H7.19866C7.39552 15.1581 7.55503 14.9985 7.55503 14.8017C7.55503 14.6049 7.39552 14.4453 7.19866 14.4453H6.81853C6.62167 14.4453 6.46216 14.6049 6.46216 14.8017C6.46216 14.9985 6.62167 15.1581 6.81853 15.1581Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M6.81853 9.45493H7.19866C7.39552 9.45493 7.55503 9.29537 7.55503 9.09856C7.55503 8.90175 7.39552 8.74219 7.19866 8.74219H6.81853C6.62167 8.74219 6.46216 8.90175 6.46216 9.09856C6.46216 9.29537 6.62167 9.45493 6.81853 9.45493Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M6.81853 13.2577H7.19866C7.39552 13.2577 7.55503 13.0981 7.55503 12.9013C7.55503 12.7045 7.39552 12.5449 7.19866 12.5449H6.81853C6.62167 12.5449 6.46216 12.7045 6.46216 12.9013C6.46216 13.0981 6.62167 13.2577 6.81853 13.2577Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M6.81853 17.0584H7.19866C7.39552 17.0584 7.55503 16.8989 7.55503 16.7021C7.55503 16.5053 7.39552 16.3457 7.19866 16.3457H6.81853C6.62167 16.3457 6.46216 16.5053 6.46216 16.7021C6.46216 16.8989 6.62167 17.0584 6.81853 17.0584Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M13.2807 12.5449C12.0362 12.5449 11.0237 13.5574 11.0237 14.8019C11.0237 16.0464 12.0362 17.059 13.2807 17.059C13.7836 17.059 14.2485 16.8935 14.6241 16.6144C14.6276 16.6119 14.6314 16.6102 14.6349 16.6076C14.6444 16.6004 14.6534 16.5929 14.662 16.585C15.1942 16.1718 15.5377 15.5264 15.5377 14.8019C15.5377 13.5574 14.5252 12.5449 13.2807 12.5449ZM12.9243 13.2996V14.4456H11.7784C11.9126 13.8798 12.3586 13.4339 12.9243 13.2996ZM11.7784 15.1583H13.1025L13.8969 16.2175C13.708 16.3001 13.4997 16.3462 13.2807 16.3462C12.5519 16.3462 11.9397 15.8385 11.7784 15.1583ZM14.4668 15.7895L13.6371 14.6832V13.2996C14.3173 13.461 14.825 14.0731 14.825 14.8019C14.825 15.1772 14.6903 15.5216 14.4668 15.7895Z"
          fill="black"
          fill-opacity="0.54"
        />
      </g>
      <defs>
        <clipPath id="clip0_411_5342">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
